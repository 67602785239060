import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, effect, Input, input, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimeTrailingZeroPipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'elapsed-time',
	templateUrl: './elapsed-time.component.html',
	styleUrls: ['./elapsed-time.component.scss'],
	imports: [TimeTrailingZeroPipe]
})
export class ElapsedTimeComponent implements OnDestroy {
	private _showSeconds: boolean = false;

	@Input()
	set showSeconds(value: BooleanInput) {
		this._showSeconds = coerceBooleanProperty(value);
	}

	public startTimeUtc = input<Date | string | null | undefined>();

	public elapsedHours: number = 0;
	public elapsedMinutes: number = 0;
	public elapsedSeconds: number = 0;

	private _timerSubscription: Subscription | null = null;

	constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
		const startTime = this.startTimeUtc();
		if (startTime) {
			this.startTimer(startTime);
		}

		effect(() => {
			const startTime = this.startTimeUtc();
			if (startTime) {
				this.elapsedHours = 0;
				this.elapsedMinutes = 0;
				this.elapsedSeconds = 0;

				this.startTimer(startTime);
			}
		});
	}

	public ngOnDestroy(): void {
		if (this._timerSubscription) {
			this._timerSubscription.unsubscribe();
		}
	}

	private startTimer(startTime: Date | string): void {
		let startTimeUtc: Date;

		if (typeof startTime === 'string') {
			startTimeUtc = new Date(startTime);
		} else if (startTime instanceof Date) {
			startTimeUtc = startTime;
		} else {
			startTimeUtc = new Date();
		}

		this._timerSubscription = interval(1000).subscribe(() => {
			const now = new Date();
			const elapsed = Math.floor((now.getTime() - startTimeUtc.getTime()) / 1000);
			this.elapsedHours = Math.floor(elapsed / 3600);
			this.elapsedMinutes = Math.floor((elapsed % 3600) / 60);
			this.elapsedSeconds = elapsed % 60;

			this._changeDetectorRef.detectChanges();
		});
	}
}
