/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SummaryTypeApiEnum {
    Count = 0,
    Text = 1,
    Data = 2,
    True = 3,
    False = 4,
}

export const SummaryTypeApiEnumDisplayMap : { [key in SummaryTypeApiEnum] : string } = {
    [SummaryTypeApiEnum.Count]: "Count",
    [SummaryTypeApiEnum.Text]: "Text",
    [SummaryTypeApiEnum.Data]: "Data",
    [SummaryTypeApiEnum.True]: "True",
    [SummaryTypeApiEnum.False]: "False",
}
