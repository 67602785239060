import { Pipe, PipeTransform } from '@angular/core';
import { defaultFormatter } from '../formatters';
@Pipe({
	name: 'default',
	standalone: true
})
export class DefaultPipe implements PipeTransform {
	transform(value: unknown, defaultValue: string = '--'): string {
		return defaultFormatter(value, defaultValue);
	}
}
