import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChildrenOutletContexts, RouterModule } from '@angular/router';
import { fadeInOutRouteAnimation } from '../../shared/animations/fade-in-out-route-animation';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrl: './auth-layout.component.scss',
	imports: [RouterModule],
	animations: [fadeInOutRouteAnimation]
})
export class AuthLayoutComponent {
	constructor(private readonly _contexts: ChildrenOutletContexts) {}

	getRouteAnimationData() {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}
}
