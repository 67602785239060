/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderTypeApiEnum {
    AncillaryOrder = 0,
    LaboratoryOrder = 1,
    PharmacyOrder = 2,
    RadiologyOrder = 3,
}

export const OrderTypeApiEnumDisplayMap : { [key in OrderTypeApiEnum] : string } = {
    [OrderTypeApiEnum.AncillaryOrder]: "AncillaryOrder",
    [OrderTypeApiEnum.LaboratoryOrder]: "LaboratoryOrder",
    [OrderTypeApiEnum.PharmacyOrder]: "PharmacyOrder",
    [OrderTypeApiEnum.RadiologyOrder]: "RadiologyOrder",
}
