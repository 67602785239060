import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { tap } from 'rxjs';
import { MFATypeEnum } from '../../auth';
import { UserMFASettingsApiModel } from '../../generated-models';
import {
	AdminContainerComponent,
	AppSidenavConfig,
	AppSidenavService,
	ConfirmationService,
	TableContainerComponent
} from '../../layout';
import { BaseIsProcessingComponent, BooleanPipe, HasClaimDirective, SecurityService } from '../../shared';
import { DefaultPipe } from '../../shared/pipes/default.pipe';
import {
	AddEditMFAPhoneNumberComponent,
	AddEditMFAPhoneNumberComponentData
} from './add-edit-mfa-phone-number';
import { AddEditMFATOTPComponent, AddEditMFATOTPComponentData } from './add-edit-mfa-totp';
import { UserMFASettingsService } from './user-mfa-settings.service';

export interface MFARowItem {
	mfaType: MFATypeEnum;
	mfaTypeName: string;
	isSetup: boolean;
	canRemove: boolean;
	canChange: boolean;
	disabled: boolean;
}

@Component({
	standalone: true,
	selector: 'user-mfa-settings',
	templateUrl: './user-mfa-settings.component.html',
	styleUrl: './user-mfa-settings.component.scss',
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		forwardRef(() => AdminContainerComponent),
		HasClaimDirective,
		TableContainerComponent,
		MatTableModule,
		DefaultPipe,
		MatMenuModule,
		BooleanPipe
	],
	providers: [SecurityService]
})
export class UserMFASettingsComponent extends BaseIsProcessingComponent implements OnInit {
	public readonly dataSource = new MatTableDataSource<MFARowItem>([]);

	public columnsToDisplay = ['mfaType', 'setup', 'actions'];

	private _userMFASettings: UserMFASettingsApiModel | null = null;

	constructor(
		readonly _securityService: SecurityService,
		private readonly _userMFASettingService: UserMFASettingsService,
		private readonly _confirmationService: ConfirmationService,
		public readonly appSidenavService: AppSidenavService
	) {
		super([_userMFASettingService.isProcessing$]);

		this._securityService.claims = this._userMFASettingService.baseData?.claims ?? [];
	}

	public ngOnInit(): void {
		this.load();
	}

	public load(): void {
		this._userMFASettingService
			.loadUserMFASettings()
			.pipe(
				tap(response => {
					this._userMFASettings = response.body;

					const data: MFARowItem[] = [];

					if (this._securityService.hasClaim('UsePhoneNumberMFA')) {
						data.push({
							mfaType: MFATypeEnum.PhoneNumber,
							mfaTypeName: 'Voice/SMS',
							isSetup: false,
							canRemove: true,
							canChange: true,
							disabled: !this._userMFASettings?.phoneNumberMFAActive
						} as MFARowItem);
					}

					if (this._securityService.hasClaim('UseTOTPMFA')) {
						data.push({
							mfaType: MFATypeEnum.TOTP,
							mfaTypeName: 'Authenticator (TOTP)',
							isSetup: false,
							canRemove: true,
							canChange: true,
							disabled: !this._userMFASettings?.totpmfaActive
						} as MFARowItem);
					}

					this.dataSource.data = data;
				})
			)
			.subscribe();
	}

	public save(): void {}

	public change(row: MFARowItem): void {
		if (row.mfaType == MFATypeEnum.PhoneNumber) {
			this.addEditMFAPhoneNumber(true);
		} else if (row.mfaType == MFATypeEnum.TOTP) {
			this.addEditMFATOTP(true);
		}
	}

	public register(row: MFARowItem): void {
		if (row.mfaType == MFATypeEnum.PhoneNumber) {
			this.addEditMFAPhoneNumber();
		} else if (row.mfaType == MFATypeEnum.TOTP) {
			this.addEditMFATOTP();
		}
	}

	public remove(row: MFARowItem): void {
		if (row.mfaType == MFATypeEnum.PhoneNumber) {
			this._confirmationService.showConfirmation(
				`Are you sure you want to remove Voice/SMS MFA`,
				null,
				null,
				null,
				() => {},
				null
			);
		} else if (row.mfaType == MFATypeEnum.TOTP) {
			this._confirmationService.showConfirmation(
				`Are you sure you want to remove Authenticator (TOTP) MFA`,
				null,
				null,
				null,
				() => {},
				null
			);
		}
	}

	public addEditMFAPhoneNumber(isEdit: boolean = false): void {
		const sidenavData = {
			phoneNumberCountryId: this._userMFASettings?.phoneCountryId,
			phoneNumber: this._userMFASettings?.phoneNumber
		} as AddEditMFAPhoneNumberComponentData;

		const sidenavConfig = {
			title: `${isEdit ? 'Change' : 'Register'} MFA Phone Number`,
			onCloseCallback: () => {
				this.load();
			}
		} as AppSidenavConfig;

		this.appSidenavService.showSidenav(AddEditMFAPhoneNumberComponent, sidenavData, sidenavConfig);
	}

	public addEditMFATOTP(isEdit: boolean = false): void {
		const sidenavData = {
			isEdit: isEdit
		} as AddEditMFATOTPComponentData;

		const sidenavConfig = {
			title: `${isEdit ? 'Change' : 'Register'} MFA TOTP`,
			onCloseCallback: () => {
				this.load();
			}
		} as AppSidenavConfig;

		this.appSidenavService.showSidenav(AddEditMFATOTPComponent, sidenavData, sidenavConfig);
	}
}
