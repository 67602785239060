@if (isProcessing$ | async) {
	<div class="loading-container">
		<mat-spinner></mat-spinner>
	</div>
} @else {
	<div class="content-container">
		<ng-content></ng-content>
	</div>
	<div class="container-action-content">
		<ng-content select="[container-action-content]"></ng-content>
	</div>
}
