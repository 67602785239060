import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	TOTPSecretApiModel,
	UserMFASettingsApiModel,
	UserMFASettingsBaseDataApiModel,
	ValidateSetupPhoneNumberFMARequestApiModel,
	ValidateSetupTOTPMFARequestApiModel
} from '../../generated-models';
import { NotificationService } from '../../layout';
import { BaseService, handleResponse, HandleResponseConfig } from '../../shared';

@Injectable({
	providedIn: 'root'
})
export class UserMFASettingsService extends BaseService<UserMFASettingsBaseDataApiModel> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings/mfa', _httpClient, _notificationService);
	}

	public setupPhoneNumberMFA(isCall: boolean = false): Observable<HttpResponse<void>> {
		return this._httpClient
			.get<void>(`${this.apiEndpoint}/setup-phone-number-mfa${isCall ? '?iscall=true' : ''}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public loadUserMFASettings(): Observable<HttpResponse<UserMFASettingsApiModel>> {
		return this._httpClient
			.get<UserMFASettingsApiModel>(`${this.apiEndpoint}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public validateSetupPhoneNumberMFA(
		request: ValidateSetupPhoneNumberFMARequestApiModel
	): Observable<HttpResponse<void>> {
		return this._httpClient
			.post<void>(`${this.apiEndpoint}/validate-setup-phone-number-mfa`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public setupTOTPMFA(): Observable<HttpResponse<TOTPSecretApiModel>> {
		return this._httpClient
			.get<TOTPSecretApiModel>(`${this.apiEndpoint}/setup-totp-mfa`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public validateSetupTOTPMFA(request: ValidateSetupTOTPMFARequestApiModel): Observable<HttpResponse<void>> {
		return this._httpClient
			.post<void>(`${this.apiEndpoint}/validate-setup-totp-mfa`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}
}
