/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EnableWhenBehavior {
    All = 0,
    Any = 1,
}

export const EnableWhenBehaviorDisplayMap : { [key in EnableWhenBehavior] : string } = {
    [EnableWhenBehavior.All]: "All",
    [EnableWhenBehavior.Any]: "Any",
}
