import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

export interface ChipTypeaheadOption {
	key: string | null;
	label: string;
}

@Component({
	standalone: true,
	selector: 'chip-multi-select-typeahead-input-form-field',
	templateUrl: './chip-multi-select-typeahead-input-form-field.component.html',
	styleUrl: './chip-multi-select-typeahead-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatChipsModule,
		MatIconModule,
		MatAutocompleteModule
	]
})
export class ChipMultiSelectTypeaheadInputFormFieldComponent
	extends BaseInputFormFieldComponent
	implements OnInit
{
	@ViewChild('searchInput') searchInput!: ElementRef;

	private _allowAddingNew: boolean = false;

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input({ required: true })
	public optionsFilterCallBack: ((value: string) => Observable<ChipTypeaheadOption[]>) | null = null;

	@Input()
	set allowAddingNew(value: BooleanInput) {
		this._allowAddingNew = coerceBooleanProperty(value);
	}

	get allowAddingNew(): boolean {
		return this._allowAddingNew;
	}

	readonly separatorKeysCodes: number[] = [ENTER, COMMA];

	public readonly selectedOptionsCtrl = new FormControl<ChipTypeaheadOption[]>([]);

	public selectedOptions: ChipTypeaheadOption[] = [];

	public readonly searchInputCtrl = new FormControl<string | null>(null);

	public filteredOptions = this.searchInputCtrl.valueChanges.pipe(
		startWith(''),
		debounceTime(300),
		distinctUntilChanged(),
		switchMap(val => {
			return this.filter(val || '');
		})
	);

	get getSelectedOptions(): ChipTypeaheadOption[] {
		return this.formCtrl.value;
	}

	constructor() {
		super('The value');
	}

	public ngOnInit(): void {
		this.formCtrl.valueChanges
			.pipe(
				tap((value: ChipTypeaheadOption[]) => {
					this.selectedOptions = value;
				})
			)
			.subscribe();
	}

	private filter(value: string): Observable<ChipTypeaheadOption[]> {
		if (this.optionsFilterCallBack) {
			return this.optionsFilterCallBack(value);
		} else {
			return of([]);
		}
	}

	public add(event: MatChipInputEvent): void {
		if (this.allowAddingNew) {
			const addedOptionLabel: string = event.value;

			if (this.selectedOptions.filter(f => f.key === null && f.label === addedOptionLabel).length === 0) {
				this.selectedOptions.push({ key: null, label: addedOptionLabel } as ChipTypeaheadOption);

				this.clearSearchInput();
			}
		}
	}

	public remove(option: ChipTypeaheadOption): void {
		const indexToRemove = this.selectedOptions.findIndex(f => f.key === option.key && f.label === option.label);

		if (indexToRemove > -1) {
			this.selectedOptions.splice(indexToRemove, 1);

			this.formCtrl.setValue(this.selectedOptions, { emitEvent: false });
		}
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		const selectedOption: ChipTypeaheadOption = event.option.value;

		if (
			this.selectedOptions.filter(f => f.key === selectedOption.key && f.label === selectedOption.label)
				.length === 0
		) {
			this.selectedOptions.push(selectedOption);

			this.formCtrl.setValue(this.selectedOptions, { emitEvent: false });

			this.clearSearchInput();
		}
	}

	private clearSearchInput(): void {
		this.searchInputCtrl.reset(null, { emitEvent: false });
		this.searchInput.nativeElement.value = '';
	}
}
