/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PublicationStatus {
    Draft = 0,
    Active = 1,
    Retired = 2,
    Unknown = 3,
}

export const PublicationStatusDisplayMap : { [key in PublicationStatus] : string } = {
    [PublicationStatus.Draft]: "Draft",
    [PublicationStatus.Active]: "Active",
    [PublicationStatus.Retired]: "Retired",
    [PublicationStatus.Unknown]: "Unknown",
}
