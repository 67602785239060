import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { BaseComponent } from '../../shared';
import { environment } from '../../../environments/environment';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
	standalone: true,
	selector: 'auth-card',
	templateUrl: './auth-card.component.html',
	styleUrl: './auth-card.component.scss',
	imports: [MatCardModule]
})
export class AuthCardComponent extends BaseComponent {
	private _showInstructionsTextContent: boolean = false;

	@Input()
	public headerText: string | null = null;

	@Input()
	public headerSubText: string | null = null;

	@Input()
	set showInstructionsTextContent(value: BooleanInput) {
		this._showInstructionsTextContent = coerceBooleanProperty(value);
	}

	get showInstructionsTextContent(): boolean {
		return this._showInstructionsTextContent;
	}

	public uiVersion: string = environment.uiVersion;

	constructor() {
		super();
	}
}
