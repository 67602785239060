<form [formGroup]="formGroup">
	<mat-form-field class="full-width">
		<mat-label>Phone Country Code</mat-label>
		<mat-select name="phone-country-id" formControlName="phoneCountryId">
			@for (country of countries; track country.id) {
				<mat-option [value]="country.id">+{{ country.phoneCountryCode }} - {{ country.name }}</mat-option>
			}
		</mat-select>
		@if (phoneCountryIdCtrl.hasError('required')) {
			<mat-error>A phone country code selection is required.</mat-error>
		}
	</mat-form-field>

	<text-input-form-field
		label="Phone Number"
		[formCtrl]="phoneNumberCtrl"
		focusInitial
		fullWidth
		phoneNumber></text-input-form-field>

	@if (phoneNumberCtrl.dirty) {
		<button mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
			<mat-icon aria-hidden="false">refresh</mat-icon>Request Confirmation Code
		</button>

		<text-input-form-field
			label="Confirmation Code"
			[formCtrl]="phoneNumberChangeConfirmationCodeCtrl"
			fullWidth></text-input-form-field>
	}
</form>

<div class="action-buttons">
	<button mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
		<mat-icon aria-hidden="false">save</mat-icon>Save
	</button>
</div>
