import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
	standalone: true,
	selector: 'info-card',
	templateUrl: './info-card.component.html',
	styleUrl: './info-card.component.scss',
	imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule]
})
export class InfoCardComponent {
	private _editable: boolean = false;

	@Input()
	public title: string | null = null;

	@Input()
	set editable(value: BooleanInput) {
		this._editable = coerceBooleanProperty(value);
	}

	get editable(): boolean {
		return this._editable;
	}
}
