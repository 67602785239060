import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BaseComponent } from '../base-component.component';
import { MatButtonModule } from '@angular/material/button';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'mtx-toolbar',
	templateUrl: './mtx-toolbar.component.html',
	styleUrl: './mtx-toolbar.component.scss',
	animations: [
		trigger('expand', [
			state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
			state('false', style({ height: '0', visibility: 'hidden' })),
			transition('true => false', animate(300 + 'ms ease-in')),
			transition('false => true', animate(300 + 'ms ease-out'))
		])
	],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule]
})
export class MtxToolbarComponent extends BaseComponent {
	private _isProcessing: boolean = false;
	private _useFilters: boolean = false;
	private _filtersExpanded: boolean = false;
	private _showClearFilterButton: boolean = false;
	private _disableClearFilterButton: boolean = false;

	@Input()
	set isProcessing(value: boolean | null | undefined) {
		this._isProcessing = !!value;
	}

	get isProcessing(): boolean {
		return this._isProcessing;
	}

	@Input()
	public title: string | null = null;

	@Input()
	public count: number | null = null;

	@Input()
	set useFilters(value: BooleanInput) {
		this._useFilters = coerceBooleanProperty(value);
	}

	@Input()
	set filtersExpanded(value: BooleanInput) {
		this._useFilters = coerceBooleanProperty(value);
	}

	get filtersExpanded(): boolean {
		return this._filtersExpanded;
	}

	@Input()
	set openFilters(value: BooleanInput) {
		this._filtersExpanded = coerceBooleanProperty(value);
	}

	@Input()
	set showClearFilterButton(value: BooleanInput) {
		this._showClearFilterButton = coerceBooleanProperty(value);
	}

	get showClearFilterButton(): boolean {
		return this._showClearFilterButton;
	}

	@Input()
	set disableClearFilterButton(value: BooleanInput) {
		this._disableClearFilterButton = coerceBooleanProperty(value);
	}

	get disableClearFilterButton(): boolean {
		return this._disableClearFilterButton;
	}

	@Output()
	public clearAllFilters: EventEmitter<void> = new EventEmitter();

	@Output()
	public filtersExpandedState: EventEmitter<boolean> = new EventEmitter(false);

	get useFilters(): boolean {
		return this._useFilters;
	}

	constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	public toggleFilters(): void {
		this._filtersExpanded = !this._filtersExpanded;
		this.filtersExpandedState.emit(this._filtersExpanded);
		this._changeDetectorRef.detectChanges();
	}
}
