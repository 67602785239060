import {
	BehaviorSubject,
	EMPTY,
	MonoTypeOperatorFunction,
	Observable,
	catchError,
	finalize,
	map,
	tap
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';
import { NotificationService } from '../../layout';

export interface HandleResponseConfig {
	removeIsProcessingOnCompletion?: boolean;
	showSuccessMessage?: boolean;
	hideErrorMessage?: boolean;
	defaultErrorMessage?: string;
	customErrorFilter?: (errorResponse: HttpErrorResponse) => string | null;
}

/** RXJS function that hanldles the success or failure response of an http request. */
export function handleResponse<T>(
	callback: <T>(
		response: HttpResponse<T> | HttpErrorResponse,
		notificationService: NotificationService,
		config: HandleResponseConfig | null
	) => void,
	notificationService: NotificationService,
	config: HandleResponseConfig | null = null
): MonoTypeOperatorFunction<T> {
	return (source: Observable<T>) =>
		source.pipe(
			tap((response: T) => {
				callback(response as HttpResponse<T>, notificationService, config);
			}),
			catchError((err: HttpErrorResponse) => {
				callback(err, notificationService, config);
				return EMPTY;
			})
		);
}

export abstract class BaseService<TBaseData> {
	private _apiRoute: string | null = null;

	private readonly _processingCount: BehaviorSubject<number> = new BehaviorSubject(0);

	protected readonly _baseData: BehaviorSubject<TBaseData | null> = new BehaviorSubject<TBaseData | null>(
		null
	);

	/** Observable that contains the base data of a service. */
	public readonly baseData$: Observable<TBaseData | null> = this._baseData.asObservable();

	/** Observable to determine if the service is processing something. */
	public readonly isProcessing$: Observable<boolean> = this._processingCount
		.asObservable()
		.pipe(map((processingCount: number) => processingCount > 0));

	/** Get the base data. */
	get baseData(): TBaseData | null {
		return this._baseData.getValue();
	}

	/** Determine if the service is currently processing at the point in time this is called. */
	get isProcessing(): boolean {
		return this._processingCount.getValue() > 0;
	}

	public setIsProcessing(): void {
		const currentProcessingCount = this._processingCount.getValue();
		this._processingCount.next(currentProcessingCount + 1);
	}

	public removeIsProcessing(): void {
		const currentProcessingCount = this._processingCount.getValue();
		this._processingCount.next(currentProcessingCount > 0 ? currentProcessingCount - 1 : 0);
	}

	get apiBaseEndPoint() {
		return environment.apiBaseEndpoint;
	}

	/** The api endpoint the service will use in http requests. */
	get apiEndpoint() {
		const apiBaseEndpoint = this.apiBaseEndPoint;
		const apiRoute = this._apiRoute === null ? '' : '/' + this._apiRoute;

		const apiEndpoint = `${apiBaseEndpoint}/api${apiRoute}`;
		return apiEndpoint;
	}

	constructor(
		_apiRoute: string | null,
		protected _httpClient: HttpClient,
		protected _notificationService: NotificationService
	) {
		this._apiRoute = _apiRoute;
	}

	// public ngOnDestroy(): void {
	// 	this._baseData.complete();
	// }

	/** Set the base data. */
	public setBaseData(baseData: TBaseData | null) {
		this._baseData.next(baseData);
	}

	public processResponse<T>(
		response: HttpResponse<T> | HttpErrorResponse,
		/** A reference to the notification service. */
		notificationService: NotificationService,
		config: HandleResponseConfig | null = null
	): void {
		if (response.ok) {
			if (config?.showSuccessMessage) {
				notificationService.showSuccessNotification('Success!');
			}
		} else {
			let errorMessage =
				config?.defaultErrorMessage ??
				`An http ${response.status} error occured calling the resource ${response.url}.`;

			// Check response for custom error message from the server.
			if (response instanceof HttpErrorResponse && response.error) {
				// TODO: finish this logic.
				errorMessage = response.error.title;

				if (response.error.detail) {
					errorMessage += `: ${response.error.detail}`;
				}
			}

			if (response instanceof HttpErrorResponse && config?.customErrorFilter) {
				const customErrorFilterMessage = config.customErrorFilter(response);

				// If no match on the filter let the default error message show.
				if (customErrorFilterMessage) {
					errorMessage = customErrorFilterMessage;
				}
			}

			if (!config?.hideErrorMessage) {
				notificationService.showErrorNotification(errorMessage);
			}
		}
	}

	public getBaseData(): Observable<HttpResponse<TBaseData>> {
		const abort =
			typeof ({} as TBaseData) === 'undefined' || ({} as TBaseData) == null || this._apiRoute == null;

		if (abort) {
			return new Observable();
		}

		return this._httpClient
			.get<TBaseData>(`${this.apiEndpoint}/base-data`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed to retrieve the base data.'
				} as HandleResponseConfig),
				tap(response => {
					if (response.ok) {
						this.setBaseData(response.body);
					}
				})
			);
	}

	public get<ResponseModel>(
		relativeUrl: string,
		defaultErrorMessage: string
	): Observable<HttpResponse<ResponseModel>> {
		this.setIsProcessing();

		const urlToAppend: string = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;
		return this._httpClient
			.get<ResponseModel>(`${this.apiEndpoint}/${urlToAppend}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: defaultErrorMessage
				} as HandleResponseConfig),
				finalize(() => this.removeIsProcessing())
			);
	}

	public post<PostModel, ResponseModel>(
		relativeUrl: string,
		defaultErrorMessage: string,
		postModel: PostModel | null = null
	): Observable<HttpResponse<ResponseModel>> {
		this.setIsProcessing();

		const urlToAppend: string = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;
		return this._httpClient
			.post<ResponseModel>(`${this.apiEndpoint}/${urlToAppend}`, postModel, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: defaultErrorMessage
				} as HandleResponseConfig),
				finalize(() => this.removeIsProcessing())
			);
	}

	public patch<PostModel, ResponseModel>(
		relativeUrl: string,
		defaultErrorMessage: string,
		postModel: PostModel | null = null
	): Observable<HttpResponse<ResponseModel>> {
		this.setIsProcessing();

		const urlToAppend: string = relativeUrl.startsWith('/') ? relativeUrl.substring(1) : relativeUrl;
		return this._httpClient
			.patch<ResponseModel>(`${this.apiEndpoint}/${urlToAppend}`, postModel, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: defaultErrorMessage
				} as HandleResponseConfig),
				finalize(() => this.removeIsProcessing())
			);
	}

	public delete<ResponseModel>(
		relativeUrl: string,
		defaultErrorMessage: string
	): Observable<HttpResponse<ResponseModel>> {
		this.setIsProcessing();

		return this._httpClient
			.delete<ResponseModel>(`${this.apiEndpoint}/${relativeUrl}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: defaultErrorMessage
				} as HandleResponseConfig),
				finalize(() => this.removeIsProcessing())
			);
	}
}
