/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MFAOptionApiModel {
    None = 0,
    PhoneCall = 1,
    SMS = 2,
    TOTP = 3,
}

export const MFAOptionApiModelDisplayMap : { [key in MFAOptionApiModel] : string } = {
    [MFAOptionApiModel.None]: "None",
    [MFAOptionApiModel.PhoneCall]: "PhoneCall",
    [MFAOptionApiModel.SMS]: "SMS",
    [MFAOptionApiModel.TOTP]: "TOTP",
}
