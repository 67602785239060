import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[phoneNumber]'
})
export class PhoneNumberDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event']) onInput(event: Event) {
		const input = event.target as HTMLInputElement;
		const value = input.value.replace(/\D/g, ''); // Remove non-digit characters
		const formattedValue = this.formatPhoneNumber(value);
		input.value = formattedValue;
	}

	private formatPhoneNumber(value: string): string {
		if (!value) return '';

		const length = value.length;

		if (length < 4) {
			return value;
		} else if (length < 7) {
			return `(${value.slice(0, 3)}) ${value.slice(3)}`;
		} else {
			return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 14)}`;
		}
	}
}
