import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import {
	EncounterApiModel,
	FhirSearchRequestApiModel,
	PatientApiModel,
	QuestionnaireApiModel
} from '../../generated-models';
import { NotificationService } from '../../layout/notifications';
import { BaseService } from './base-service.service';

@Injectable({
	providedIn: 'root'
})
export class FhirService extends BaseService<FhirService> {
	private patientLoadingSubject = new BehaviorSubject<boolean>(false);
	private patientsLoadingSubject = new BehaviorSubject<boolean>(false);
	private patientTrackerLoadingSubject = new BehaviorSubject<boolean>(false);
	private careTeamLoadingSubject = new BehaviorSubject<boolean>(false);
	private encounterLoadingSubject = new BehaviorSubject<boolean>(false);
	private observationLoadingSubject = new BehaviorSubject<boolean>(false);
	private questionnaireLoadingSubject = new BehaviorSubject<boolean>(false);

	patientLoading$ = this.patientLoadingSubject.asObservable();
	patientsLoading$ = this.patientsLoadingSubject.asObservable();
	patientTrackerLoading$ = this.patientTrackerLoadingSubject.asObservable();
	careTeamLoading$ = this.careTeamLoadingSubject.asObservable();
	encounterLoading$ = this.encounterLoadingSubject.asObservable();
	observationLoading$ = this.observationLoadingSubject.asObservable();
	questionnaireLoading$ = this.questionnaireLoadingSubject.asObservable();

	private patientSubject = new BehaviorSubject<PatientApiModel | null>(null);
	private patientsSubject = new BehaviorSubject<PatientApiModel[]>([]);
	private patientTrackerSubject = new BehaviorSubject<EncounterApiModel | null>(null);
	private encounterSubject = new BehaviorSubject<EncounterApiModel | null>(null);
	private questionnaireSubject = new BehaviorSubject<QuestionnaireApiModel | null>(null);

	patient$ = this.patientSubject.asObservable();
	patients$ = this.patientsSubject.asObservable();
	patientTracker$ = this.patientTrackerSubject.asObservable();
	encounter$ = this.encounterSubject.asObservable();
	questionnaire$ = this.questionnaireSubject.asObservable();

	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('fhir', _httpClient, _notificationService);
	}

	/*
	public createPatientFromMessage(
		convertRequest: FhirConvertRequest
	): Observable<HttpResponse<PatientTracker>> {
		this.setIsProcessing();

		return this._httpClient
			.post<PatientTracker>(`${this.apiEndpoint}/patient`, convertRequest, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: '',
					hideErrorMessage: true
				} as HandleResponseConfig),
				tap(response => {
					console.log('response', response);
				}),
				finalize(() => this.removeIsProcessing())
			);
	}
			*/

	fetchPatientBySearchParams(
		searchParams: FhirSearchRequestApiModel
	): Observable<PatientApiModel[] | [] | null> {
		this.patientsLoadingSubject.next(true);
		return this._httpClient.post<PatientApiModel[]>(`${this.apiEndpoint}/patient/search`, searchParams).pipe(
			tap(patients => {
				this.patientsSubject.next(patients);
				this.patientLoadingSubject.next(false);
			}),
			catchError(error => {
				console.error('Error fetching patient', error);
				this.patientLoadingSubject.next(false);
				return of(null);
			})
		);
	}

	fetchPatient(patientId: string): void {
		this.patientLoadingSubject.next(true);
		this._httpClient
			.get<PatientApiModel>(`/api/patients/${patientId}`)
			.pipe(
				tap(patient => {
					this.patientSubject.next(patient);
					this.patientLoadingSubject.next(false);
				}),
				catchError(error => {
					console.error('Error fetching patient', error);
					this.patientLoadingSubject.next(false);
					return of(null);
				})
			)
			.subscribe();
	}

	// fetchCareTeam(careTeamId: string): void {
	// 	this.careTeamLoadingSubject.next(true);
	// 	this.http
	// 		.get<CareTeam>(`/api/careTeams/${careTeamId}`)
	// 		.pipe(
	// 			tap(careTeam => {
	// 				this.careTeamSubject.next(careTeam);
	// 				this.careTeamLoadingSubject.next(false);
	// 			}),
	// 			catchError(error => {
	// 				console.error('Error fetching care team', error);
	// 				this.careTeamLoadingSubject.next(false);
	// 				return of(null);
	// 			})
	// 		)
	// 		.subscribe();
	// }

	fetchEncounter(encounterId: string): void {
		this.encounterLoadingSubject.next(true);
		this._httpClient
			.get<EncounterApiModel>(`/api/encounters/${encounterId}`)
			.pipe(
				tap(encounter => {
					this.encounterSubject.next(encounter);
					this.encounterLoadingSubject.next(false);
				}),
				catchError(error => {
					console.error('Error fetching encounter', error);
					this.encounterLoadingSubject.next(false);
					return of(null);
				})
			)
			.subscribe();
	}

	fetchQuestionnaire(questionnaireId: string): void {
		this.questionnaireLoadingSubject.next(true);
		this._httpClient
			.get<QuestionnaireApiModel>(`/api/questionnaires/${questionnaireId}`)
			.pipe(
				tap(questionnaire => {
					this.questionnaireSubject.next(questionnaire);
					this.questionnaireLoadingSubject.next(false);
				}),
				catchError(error => {
					console.error('Error fetching questionnaire', error);
					this.questionnaireLoadingSubject.next(false);
					return of(null);
				})
			)
			.subscribe();
	}
}
