import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { QrCodeModule } from 'ng-qrcode';
import { take, takeUntil, tap } from 'rxjs';
import { ValidateSetupTOTPMFARequestApiModel } from '../../generated-models';
import { AppLoaderService } from '../../layout';
import { BaseIsProcessingComponent, MFACodeInputFormFieldComponent, mfaCodeValidator } from '../../shared';
import { TextInputFormFieldComponent } from '../../shared/components/input-form-fields/text-input-form-field/text-input-form-field.component';
import { UserMFASettingsService } from '../../user-settings';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'mfa-topt-setup',
	templateUrl: './mfa-totp-setup.component.html',
	styleUrl: './mfa-totp-setup.component.scss',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		forwardRef(() => TextInputFormFieldComponent),
		QrCodeModule,
		MFACodeInputFormFieldComponent
	]
})
export class MFATOTPSetupComponent extends BaseIsProcessingComponent implements OnInit {
	public secretUri: string | null = null;
	public secret: string | null = null;
	public showGenerateSecretPanel: boolean = false;

	public formGroup: FormGroup = new FormGroup({
		totpConfirmationCodeCtrl: new FormControl<string | null>(null, [mfaCodeValidator()])
	});

	get totpConfirmationCodeCtrl() {
		return this.formGroup.get('totpConfirmationCodeCtrl') as FormControl;
	}

	constructor(
		readonly _appLoaderService: AppLoaderService,
		public readonly authService: AuthService,
		private readonly _userMFASettingsService: UserMFASettingsService,
		private readonly _router: Router,
		private readonly _cdr: ChangeDetectorRef
	) {
		super([authService.isProcessing$, _userMFASettingsService.isProcessing$]);

		const processId = 'mfa-totp-setup-load-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();
	}

	public ngOnInit(): void {
		this.setupTOTPMFA();
	}

	public setupTOTPMFA(): void {
		this._userMFASettingsService
			.setupTOTPMFA()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok && response.body) {
						this.showGenerateSecretPanel = true;
						this.secret = response.body.secret ?? null;
						this.secretUri = response.body.secretUri ?? null;
						this._cdr.detectChanges();
					}
				})
			)
			.subscribe();
	}

	public validateSetupTOTPMFA(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const request: ValidateSetupTOTPMFARequestApiModel = {
				code: this.totpConfirmationCodeCtrl.value
			};

			this._userMFASettingsService
				.validateSetupTOTPMFA(request)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this._router.navigate([`/${this.authService.tenantCode}`]);
						}
					})
				)
				.subscribe();
		}
	}
}
