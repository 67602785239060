import { Directive, ElementRef, HostListener, input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[gatedInput]'
})
export class GatedInputDirective {
	public gateFunction = input.required<(inputValue: string) => boolean>();

	constructor(private el: ElementRef) {}

	@HostListener('beforeinput', ['$event'])
	onBeforeInputChange(event: InputEvent) {
		if (!event.data) {
			return;
		}
		const inputElement = this.el.nativeElement as HTMLInputElement;
		const currentValue = inputElement.value ?? '';
		const selectionStart = inputElement.selectionStart || 0;
		const selectionEnd = inputElement.selectionEnd || 0;

		// Simulate what the value will be after the input
		const newValue =
			currentValue.substring(0, selectionStart) + event.data + currentValue.substring(selectionEnd);

		// Check the new value against the regex to see if it's good
		const gate = this.gateFunction();
		if (!gate(newValue)) {
			event.preventDefault();
		}
	}
}
