<mat-card [ngClass]="{ disabled: disabled }">
	@if (disabled) {
		<div class="disabled-overlay"></div>
	}
	<mat-card-header>
		@if (title) {
			<mat-card-title>
				{{ title }}
			</mat-card-title>
		}
	</mat-card-header>
	<mat-card-content>
		<ng-content></ng-content>
	</mat-card-content>
	@if (noFooterContent) {
		<div class="no-footer-content"></div>
	} @else {
		<mat-card-footer>
			<ng-content select="[action-card-footer-content]"></ng-content>
		</mat-card-footer>
	}
</mat-card>
