/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AddressTypeApiEnum {
    Physical = 0,
    Postal = 1,
    Both = 2,
}

export const AddressTypeApiEnumDisplayMap : { [key in AddressTypeApiEnum] : string } = {
    [AddressTypeApiEnum.Physical]: "Physical",
    [AddressTypeApiEnum.Postal]: "Postal",
    [AddressTypeApiEnum.Both]: "Both",
}
