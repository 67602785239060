@if (showFormField) {
	<div class="form-field-wrapper">
		@if (readonly()) {
			<readonly-display-input
				[ngClass]="{ 'full-width': fullWidth }"
				[label]="label"
				[value]="internalControl.value?.toString()"
				[suffix]="temperatureSymbol" />
			<readonly-display-input [value]="otherValueDisplay" [suffix]="otherTemperatureSymbol" />
		} @else {
			<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
				@if (label) {
					<mat-label style="white-space: nowrap">{{ label }}</mat-label>
				}
				@if (focusInitial) {
					<input
						matInput
						type="text"
						[placeholder]="placeHolder ? placeHolder : ''"
						[formControl]="internalControl"
						[maxlength]="7"
						cdkFocusInitial
						gatedInput
						[gateFunction]="temperatureGate" />
				} @else {
					<input
						matInput
						type="text"
						[placeholder]="placeHolder ? placeHolder : ''"
						[formControl]="internalControl"
						[maxlength]="7"
						gatedInput
						[gateFunction]="temperatureGate" />
				}
				<span class="suffix" matSuffix>{{ temperatureSymbol }} </span>
			</mat-form-field>
			<readonly-display-input [value]="otherValueDisplay" [suffix]="otherTemperatureSymbol" />
		}
	</div>
	@if (formCtrl && formCtrl.hasError('required')) {
		<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
	}

	@if (formCtrl && formCtrl.hasError('temperatureInvalidRange')) {
		<mat-error>{{ errorLabelToLowerExceptFirst }} is an invalid range.</mat-error>
	}

	<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
		@if (formCtrl && formCtrl.hasError(customError.type)) {
			<mat-error>{{ customError.message }}</mat-error>
		}
	</ng-template>
}
