import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { map, Observable, of, take } from 'rxjs';
import { AuthService } from './auth-service.service';

export const AuthGuard: CanActivateFn = (): Observable<boolean> => {
	const authService = inject(AuthService);
	const router = inject(Router);

	if (authService.isLoggedIn()) {
		return of(true);
	} else {
		return authService.loadUserInfoIfValidSession().pipe(
			take(1),
			map(response => {
				if (response.ok) {
					return true;
				} else {
					router.navigate([`${authService.tenantCode}/auth/login`]);
					return false;
				}
			})
		);
	}
};
