import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/** An injection token for injecting data into components. */
export const DATA_INJECTION_TOKEN = new InjectionToken<string>(
	'DATA_INJECTION_TOKEN'
);

/**
 * A model for the sidenav config.
 */
export interface AppSidenavConfig {
	/** The title to show on the sidenav. */
	title?: string | null;
	/** the subtitle to show on the sidenav. */
	subtitle?: string | null;
	/** A function to run when the side nav is closed with the option to run the onCloseCallback. */
	onCloseCallback?: (() => void) | null;
}

/** A service for managing sidenavs. */
@Injectable({
	providedIn: 'root'
})
export class AppSidenavService {
	public readonly opened: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public readonly opened$: Observable<boolean> = this.opened.asObservable();

	public readonly componentPortal: BehaviorSubject<ComponentPortal<unknown> | null> =
		new BehaviorSubject<ComponentPortal<unknown> | null>(null);

	public readonly componentPortal$: Observable<ComponentPortal<unknown> | null> =
		this.componentPortal.asObservable();

	public readonly sidenavConfig: BehaviorSubject<AppSidenavConfig | null> =
		new BehaviorSubject<AppSidenavConfig | null>(null);

	public readonly sidenavConfig$: Observable<AppSidenavConfig | null> =
		this.sidenavConfig.asObservable();

	public readonly _isProcessing: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public readonly isProcessing$: Observable<boolean> =
		this._isProcessing.asObservable();

	/**
	 * Opens a sidenav.
	 * @param component The component to display inside the sidenav.
	 * @param sidenavData The data to pass to the component in the sidenav.
	 * @param sidenavConfig The configuration for the sidenav.
	 */
	public showSidenav<T>(
		component: ComponentType<T>,
		sidenavData?: unknown,
		sidenavConfig?: AppSidenavConfig | null
	): void {
		const componentPortal = new ComponentPortal(
			component,
			null,
			Injector.create({
				providers: [{ provide: DATA_INJECTION_TOKEN, useValue: sidenavData }]
			})
		);

		this.componentPortal.next(componentPortal);
		this.sidenavConfig.next(sidenavConfig ?? null);
		this.opened.next(true);
	}

	/**
	 * Close the sidenav.
	 * @param runOnCloseCallback Run the runOnCloseCallback method if one was provided when the sidenav is closed.
	 */
	public closeSidenav(runOnCloseCallback: boolean = false): void {
		this.opened.next(false);

		if (runOnCloseCallback) {
			const onCloseCallback = this.sidenavConfig.getValue()?.onCloseCallback;
			if (onCloseCallback) {
				onCloseCallback();
			}
		}

		// Clear the options.
		this.sidenavConfig.next(null);
		this.componentPortal.next(null);
		this._isProcessing.next(false);
	}
}
