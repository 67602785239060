import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationTypeEnum } from '../models';

/** A service for handling notifications. */
@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private _notificationQueue: Notification[] = [];

	private readonly _notificationQueueCount: Subject<number> = new Subject();

	public readonly notificationQueueCount$: Observable<number> = this._notificationQueueCount.asObservable();

	constructor() {}

	public getNextNotificationFromQueue(): Notification | null {
		if (this._notificationQueue?.length > 0) {
			// Get the last (oldest) value and remove it from the array, LIFO.
			const nextNotification: Notification = this._notificationQueue.splice(
				this._notificationQueue.length - 1,
				1
			)[0];

			// Update the notification queue count.
			this._notificationQueueCount.next(this._notificationQueue.length);
			return nextNotification;
		} else {
			return null;
		}
	}

	public showErrorNotification(message: string) {
		this._notificationQueue.push(new Notification(NotificationTypeEnum.Error, message));
		this._notificationQueueCount.next(this._notificationQueue.length);
	}

	public showWarningNotification(message: string) {
		this._notificationQueue.push(new Notification(NotificationTypeEnum.Warning, message));
		this._notificationQueueCount.next(this._notificationQueue.length);
	}

	public showSuccessNotification(message: string) {
		this._notificationQueue.push(new Notification(NotificationTypeEnum.Success, message));
		this._notificationQueueCount.next(this._notificationQueue.length);
	}
}
