/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContactPointSystemApiEnum {
    Fax = 0,
    Pager = 1,
    Url = 2,
    Phone = 3,
    Email = 4,
    Other = 5,
    Sms = 6,
}

export const ContactPointSystemApiEnumDisplayMap : { [key in ContactPointSystemApiEnum] : string } = {
    [ContactPointSystemApiEnum.Fax]: "Fax",
    [ContactPointSystemApiEnum.Pager]: "Pager",
    [ContactPointSystemApiEnum.Url]: "Url",
    [ContactPointSystemApiEnum.Phone]: "Phone",
    [ContactPointSystemApiEnum.Email]: "Email",
    [ContactPointSystemApiEnum.Other]: "Other",
    [ContactPointSystemApiEnum.Sms]: "Sms",
}
