@if (showFormField) {
	<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
		@if (label) {
			<mat-label>{{ label }}</mat-label>
		}

		<div class="flex-container">
			@if (focusInitial) {
				<mtx-datetimepicker
					#datetimePicker
					type="datetime"
					mode="portrait"
					[touchUi]="touchUi"
					timeInput
					twelvehour="false">
				</mtx-datetimepicker>

				<input [mtxDatetimepicker]="datetimePicker" matInput required cdkFocusInitial [formControl]="formCtrl" />
			} @else {
				<mtx-datetimepicker
					#datetimePicker
					type="datetime"
					mode="portrait"
					[touchUi]="touchUi"
					timeInput
					twelvehour="false">
				</mtx-datetimepicker>

				<input
					[mtxDatetimepicker]="datetimePicker"
					matInput
					required
					[readonly]="readonly()"
					[formControl]="formCtrl" />
				<mtx-datetimepicker-toggle [for]="datetimePicker" matSuffix></mtx-datetimepicker-toggle>
			}
		</div>

		@if (hint) {
			<mat-hint>{{ hint }}</mat-hint>
		}

		@if (formCtrl.hasError('required')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</mat-form-field>
}
