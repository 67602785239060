import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { MtxColorpickerModule } from '@ng-matero/extensions/colorpicker';

@Component({
	standalone: true,
	selector: 'color-input-form-field',
	templateUrl: './color-input-form-field.component.html',
	styleUrl: './color-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MtxColorpickerModule]
})
export class ColorInputFormFieldComponent extends BaseInputFormFieldComponent {
	constructor() {
		super('The value');
	}
}
