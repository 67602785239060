<div class="notes-container">
	@for (note of notes; track note) {
		@if (!note.delete) {
			<div class="full-width" style="position: relative">
				<mat-form-field class="full-width">
					<mat-label>{{ getNoteDisplayLabel(note) }}</mat-label>
					<textarea
						matInput
						[value]="note.text"
						[disabled]="note.fhirId && note.authorFhirId != userFhirId"
						(input)="onValueChange(note, $event)"
						cdkTextareaAutosize
						cdkAutosizeMinRows="3"></textarea>
				</mat-form-field>
				@if (!note.fhirId || note.authorFhirId == userFhirId) {
					<button
						mat-icon-button
						aria-hidden="false"
						(click)="removeNote(note)"
						style="position: absolute; right: 4px; top: 4px; z-index: 1">
						<mat-icon color="warn">delete</mat-icon>
					</button>
				}
			</div>
		}
	}
</div>

<div class="action-buttons">
	<button mat-raised-button color="primary" (click)="addNote()">
		<mat-icon aria-hidden="false">add</mat-icon>Add Note
	</button>
	<button mat-flat-button (click)="saveNotes()" [disabled]="!saveButtonEnabled">
		<mat-icon aria-hidden="false">save</mat-icon>Save
	</button>
</div>
