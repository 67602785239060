import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { IdLabelApiModel } from '../../../../generated-models';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	selector: 'radio-group-input-form-field',
	templateUrl: './radio-group-input-form-field.component.html',
	styleUrl: './radio-group-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatRadioModule]
})
export class RadioGroupInputFormFieldComponent extends BaseInputFormFieldComponent {
	@Input({ required: true })
	public options: IdLabelApiModel[] | undefined = [];

	constructor() {
		super('');
	}
}
