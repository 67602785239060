export function lowercaseExceptFirst(value: string | null | undefined): string | null | undefined {
	if (!value || value.length === 0) {
		return value;
	}

	const firstLetter = value.charAt(0).toUpperCase();
	const restOfString = value.slice(1).toLowerCase();

	return firstLetter + restOfString;
}
