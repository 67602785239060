import {
	HttpEvent,
	HttpRequest,
	HttpInterceptorFn,
	HttpHandlerFn,
	HttpErrorResponse
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

export const backendDownInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const router = inject(Router);

	return next(req).pipe(
		catchError(err => {
			if (err instanceof HttpErrorResponse && err.status === 0) {
				router.navigate(['/backend-down']);

				return throwError(() => err);
			} else {
				return throwError(() => err);
			}
		})
	);
};
