import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'split',
	standalone: true
})
export class SplitPipe implements PipeTransform {
	transform(value: unknown[], separator: string = ', '): string | null {
		if (!Array.isArray(value) || typeof separator !== 'string') {
			return null;
		}
		return value.join(separator);
	}
}
