import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	selector: 'mfa-code-input-form-field',
	templateUrl: './mfa-code-input-form-field.component.html',
	styleUrl: './mfa-code-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule]
})
export class MFACodeInputFormFieldComponent extends BaseInputFormFieldComponent {
	public maxLength = input<number>();

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	public inputType: string = 'text';

	constructor() {
		super('The value');
	}
}
