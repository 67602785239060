import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../auth-service.service';

export const authErrorInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const authService = inject(AuthService);

	return next(req).pipe(
		catchError((error: HttpErrorResponse) => {
			if (
				error instanceof HttpErrorResponse &&
				error.status === 401 &&
				!(req.url.includes('auth/login') || req.url.includes('auth/confirm-email'))
			) {
				authService.performClientSideLogoutTasks();
				return throwError(() => error);
			} else if (error instanceof HttpErrorResponse && error.status === 403 && error.error.detail == 'PasswordExpired') {
				authService.performPasswordExpiredTasks();
				return throwError(() => error);
			} else if (
				error instanceof HttpErrorResponse &&
				error.status === 403 &&
				error.error.detail == 'MFAPhoneNumberSetupRequired'
			) {
				authService.performMFAPhoneNumberSetupTasks();
				return throwError(() => error);
			} else if (
				error instanceof HttpErrorResponse &&
				error.status === 403 &&
				error.error.detail == 'MFATOTPSetupRequired'
			) {
				authService.performMFATOTPSetupTasks();
				return throwError(() => error);
			} else {
				return throwError(() => error);
			}
		})
	);
};
