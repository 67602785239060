/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IdentifierUseApiEnum {
    Usual = 0,
    Secondary = 1,
    Official = 2,
    Old = 3,
    Temp = 4,
}

export const IdentifierUseApiEnumDisplayMap : { [key in IdentifierUseApiEnum] : string } = {
    [IdentifierUseApiEnum.Usual]: "Usual",
    [IdentifierUseApiEnum.Secondary]: "Secondary",
    [IdentifierUseApiEnum.Official]: "Official",
    [IdentifierUseApiEnum.Old]: "Old",
    [IdentifierUseApiEnum.Temp]: "Temp",
}
