export function booleanFormatter(
	value: boolean,
	trueValue: string = 'Yes',
	falseValue: string = 'No'
): string {
	if (value) {
		return trueValue;
	} else {
		return falseValue;
	}
}
