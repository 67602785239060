/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GenderApiEnum {
    Unknown = 0,
    Male = 1,
    Female = 2,
    Other = 3,
}

export const GenderApiEnumDisplayMap : { [key in GenderApiEnum] : string } = {
    [GenderApiEnum.Unknown]: "Unknown",
    [GenderApiEnum.Male]: "Male",
    [GenderApiEnum.Female]: "Female",
    [GenderApiEnum.Other]: "Other",
}
