<mat-form-field class="short" floatLabel="always">
	@if (label()) {
		<mat-label>{{ label() }}</mat-label>
	}
	<span class="data-container">
		<span>
			<input style="display: none" matInput [disabled]="true" [readonly]="true" />
			{{ value() ?? '--' }}
		</span>
		@if (icon()) {
			<mat-icon>{{ icon() }}</mat-icon>
		}
		@if (suffix()) {
			<span>{{ suffix() }}</span>
		}
	</span>
</mat-form-field>
