/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NameUseApiEnum {
    Maiden = 0,
    Official = 1,
    Nickname = 2,
    Anonymous = 3,
    Usual = 4,
    Old = 5,
    Temp = 6,
}

export const NameUseApiEnumDisplayMap : { [key in NameUseApiEnum] : string } = {
    [NameUseApiEnum.Maiden]: "Maiden",
    [NameUseApiEnum.Official]: "Official",
    [NameUseApiEnum.Nickname]: "Nickname",
    [NameUseApiEnum.Anonymous]: "Anonymous",
    [NameUseApiEnum.Usual]: "Usual",
    [NameUseApiEnum.Old]: "Old",
    [NameUseApiEnum.Temp]: "Temp",
}
