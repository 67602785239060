import { CommonModule } from '@angular/common';
import { Component, input, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { GatedInputDirective } from '../../../directives/gated-input.directive';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { ReadonlyDisplayInputComponent } from '../readonly-display-input/readonly-display-input.component';

declare type TemperatureType = 'celsius' | 'fahrenheit';

const defaultWeightType: TemperatureType = 'celsius';
const fahrenheitSymbol = '°F';
const celsiusSymbol = '°C';

@Component({
	standalone: true,
	selector: 'temperature-input-form-field',
	templateUrl: './temperature-input-form-field.component.html',
	styleUrl: './temperature-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		GatedInputDirective,
		ReadonlyDisplayInputComponent
	]
})
export class TemperatureInputFormFieldComponent
	extends BaseInputFormFieldComponent
	implements OnInit, OnDestroy
{
	private _destroy: Subject<void> = new Subject();
	private _visibleControl = new FormControl<number | null>({ value: null, disabled: false });

	private _temperatureType: TemperatureType = defaultWeightType;
	private _temperatureSymbol: string = celsiusSymbol;
	private _othertemperatureSymbol: string = fahrenheitSymbol;

	public readonly = input<boolean>(false);

	public temperatureGate = (inputValue: string): boolean => {
		return inputValue == '' || /^\d*(\.)?(\d{1,3})?$/.test(inputValue);
	};

	@Input({ required: true })
	set temperatureType(value: TemperatureType) {
		this._temperatureType = value;

		switch (this._temperatureType) {
			case 'celsius':
				this._temperatureSymbol = celsiusSymbol;
				this._othertemperatureSymbol = fahrenheitSymbol;
				break;

			case 'fahrenheit':
				this._temperatureSymbol = fahrenheitSymbol;
				this._othertemperatureSymbol = celsiusSymbol;
				break;
		}
	}

	get internalControl(): FormControl<number | null> {
		return this._visibleControl;
	}

	get temperatureSymbol(): string | null {
		return this._temperatureSymbol;
	}

	get otherTemperatureSymbol(): string | null {
		return this._othertemperatureSymbol;
	}

	get otherValueDisplay(): string | null {
		const converted =
			this._temperatureType == 'celsius'
				? this.convertCelciusToFahrenheit(this.formCtrl.value)
				: this.formCtrl.value;
		return converted?.toString() ?? null;
	}

	get placeHolder(): string | null {
		return null;
	}

	constructor() {
		super('The value');
	}

	private convertFahrenheitToCelsius(fahrenheit: number | null): number | null {
		if (fahrenheit == null) {
			return null;
		}
		const convertedNumber = (fahrenheit - 32) * (5 / 9);
		// Round to 3 decimal places
		return Math.round(convertedNumber * 1000) / 1000;
	}

	private convertCelciusToFahrenheit(celcius: number | null): number | null {
		if (celcius == null) {
			return null;
		}

		const convertedNumber = celcius * (9 / 5) + 32;
		// Round to 3 decimal places
		return Math.round(convertedNumber * 1000) / 1000;
	}

	private syncEnabledStates() {
		if (this.formCtrl.enabled && !this._visibleControl.enabled) {
			this._visibleControl.enable();
		} else if (!this.formCtrl.enabled && this._visibleControl.enabled) {
			this._visibleControl.disable();
		}
	}

	ngOnInit(): void {
		// Whenever the user types something into our visible control
		// we convert it to celcius and put in into the formControl
		this._visibleControl.valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this._destroy))
			.subscribe((value: number | null) => {
				const numVal = parseFloat(value?.toString() ?? '');
				if (Number.isNaN(numVal)) {
					this.formCtrl.setValue(null);
				} else {
					const celsiusValue =
						this._temperatureType == 'celsius' ? numVal : this.convertFahrenheitToCelsius(numVal);
					this.formCtrl.setValue(celsiusValue);
				}
			});

		// Syncing out internal visible control with the user's formControl
		// The user's form control will always be in celsius

		const visibleStartValue =
			this._temperatureType == 'celsius'
				? this.formCtrl.value
				: this.convertCelciusToFahrenheit(this.formCtrl.value);
		this._visibleControl.setValue(visibleStartValue);
		this.syncEnabledStates();

		// If the formControl ever becomes disabled, we should disable our
		// visible control.
		this.formCtrl.statusChanges
			.pipe(
				takeUntil(this._destroy),
				tap(() => {
					this.syncEnabledStates();
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this._destroy.complete();
	}
}
