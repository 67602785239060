import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
	standalone: true,
	selector: 'date-input-form-field',
	templateUrl: './date-input-form-field.component.html',
	styleUrl: './date-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatDatepickerModule]
})
export class DateInputFormFieldComponent extends BaseInputFormFieldComponent {
	public touchUi: boolean = false;

	public readonly = input<boolean>(false);

	@Input()
	public hint: string | null | undefined = null;

	@Input()
	set useTouchUi(value: BooleanInput) {
		this.touchUi = coerceBooleanProperty(value);
	}
	constructor() {
		super('The date');
	}
}
