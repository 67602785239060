<fieldset>
	<legend>{{ itemNamePlural }}</legend>
	<mat-action-list>
		@if (listItems.length === 0) {
			<mat-list-item title="nothing to display">
				<span matListItemLine>No {{ itemNamePlural.toLowerCase() }} to display</span>
			</mat-list-item>
		}

		@for (listItem of listItems; track listItem.key + listItem.label) {
			<mat-list-item [title]="listItem.label">
				<span matListItemLine>{{ listItem.label }}</span>

				@if (!readonly()) {
					<button matListItemMeta type="button" mat-icon-button aria-label="" (click)="remove(listItem)">
						<mat-icon color="warn">delete</mat-icon>
					</button>
				}
			</mat-list-item>
		}
	</mat-action-list>

	@if (!readonly()) {
		<form [formGroup]="formGroup">
			<div class="input-container">
				<mat-form-field>
					<mat-label>Add {{ itemName }}</mat-label>
					<input matInput placeholder="" [formControl]="newItemCtrl" [matAutocomplete]="auto" />

					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
						@for (filteredOption of filteredOptions | async; track filteredOption.key) {
							<mat-option [value]="filteredOption">{{ filteredOption.label }}</mat-option>
						}
					</mat-autocomplete>

					@if (newItemCtrl && newItemCtrl.hasError('required')) {
						<mat-error>{{ itemName | lowercaseExceptFirst }} is required.</mat-error>
					}

					@if (newItemCtrl && newItemCtrl.hasError('minlength')) {
						<mat-error
							>{{ itemName | lowercaseExceptFirst }} must be more than
							{{ newItemCtrl.getError('minlength').requiredLength }}
							characters.</mat-error
						>
					}

					@if (newItemCtrl && newItemCtrl.hasError('maxlength')) {
						<mat-error
							>{{ itemName | lowercaseExceptFirst }} can only be up to
							{{ newItemCtrl.getError('maxlength').requiredLength }}
							characters.</mat-error
						>
					}

					@if (newItemCtrl && newItemCtrl.hasError('duplicate')) {
						<mat-error>This {{ itemName | lowercase }} has already been added.</mat-error>
					}
				</mat-form-field>

				<button type="submit" mat-icon-button aria-label="" [disabled]="formGroup.invalid" (click)="add()">
					<mat-icon>add</mat-icon>
				</button>
			</div>
		</form>
	}
</fieldset>
