import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Portal, PortalModule } from '@angular/cdk/portal';
import { filter, takeUntil, tap } from 'rxjs';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseComponent } from '../../shared';
import { AppLoaderDirective } from '../app-loader';
import { AppSidenavService } from './services';

@Component({
	standalone: true,
	selector: 'sidenav',
	templateUrl: './app-sidenav.component.html',
	styleUrl: './app-sidenav.component.scss',
	imports: [
		CommonModule,
		MatSidenavModule,
		MatIconModule,
		MatButtonModule,
		MatCardModule,
		MatSidenavModule,
		PortalModule,
		MatProgressSpinnerModule,
		OverlayModule,
		AppLoaderDirective
	]
})
export class AppSidenavComponent
	extends BaseComponent
	implements AfterViewInit
{
	public sidenavContentPortal!: Portal<unknown>;

	constructor(public appSidenavService: AppSidenavService) {
		super();
	}

	public ngAfterViewInit() {
		this.appSidenavService.opened$
			.pipe(
				takeUntil(this._destroy),
				filter(opened => opened),
				tap(() => {
					this.sidenavContentPortal =
						this.appSidenavService.componentPortal.getValue()!;
				})
			)
			.subscribe();
	}
}
