@if (showFormField()) {
	<form [formGroup]="formGroup">
		<mat-form-field [ngClass]="{ 'full-width': fullWidth }">
			@if (label) {
				<mat-label>{{ label }}</mat-label>
			}
			<input
				#searchInput
				type="text"
				matInput
				[attr.placeholder]="placeHolder"
				[formControl]="searchInputCtrl"
				[matAutocomplete]="auto" />

			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				@if (filteredOptions.length > 0) {
					@for (filteredOption of filteredOptions; track filteredOption.key) {
						<mat-option [value]="filteredOption">{{ filteredOption.label }}</mat-option>
					}
				} @else {
					<mat-optgroup label="Favorites (type for more options)">
						@for (favoriteOption of favoriteOptions; track favoriteOption.key) {
							<mat-option [value]="favoriteOption"
								>{{ favoriteOption.label }}
								<mat-icon class="primary-color filled-star-hack">star_half</mat-icon></mat-option
							>
						}
					</mat-optgroup>
				}
			</mat-autocomplete>

			@if (searchInputCtrl && searchInputCtrl.hasError('required')) {
				<mat-error> is required.</mat-error>
			}
		</mat-form-field>
	</form>
}
