/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionnaireItemTypeApiEnum {
    Display = 0,
    Decimal = 1,
    Quantity = 2,
    Url = 3,
    String = 4,
    Attachment = 5,
    Boolean = 6,
    Choice = 7,
    Date = 8,
    DateTime = 9,
    Group = 10,
    Integer = 11,
    OpenChoice = 12,
    Question = 13,
    Reference = 14,
    Text = 15,
    Time = 16,
    Unknown = 17,
}

export const QuestionnaireItemTypeApiEnumDisplayMap : { [key in QuestionnaireItemTypeApiEnum] : string } = {
    [QuestionnaireItemTypeApiEnum.Display]: "Display",
    [QuestionnaireItemTypeApiEnum.Decimal]: "Decimal",
    [QuestionnaireItemTypeApiEnum.Quantity]: "Quantity",
    [QuestionnaireItemTypeApiEnum.Url]: "Url",
    [QuestionnaireItemTypeApiEnum.String]: "String",
    [QuestionnaireItemTypeApiEnum.Attachment]: "Attachment",
    [QuestionnaireItemTypeApiEnum.Boolean]: "Boolean",
    [QuestionnaireItemTypeApiEnum.Choice]: "Choice",
    [QuestionnaireItemTypeApiEnum.Date]: "Date",
    [QuestionnaireItemTypeApiEnum.DateTime]: "DateTime",
    [QuestionnaireItemTypeApiEnum.Group]: "Group",
    [QuestionnaireItemTypeApiEnum.Integer]: "Integer",
    [QuestionnaireItemTypeApiEnum.OpenChoice]: "OpenChoice",
    [QuestionnaireItemTypeApiEnum.Question]: "Question",
    [QuestionnaireItemTypeApiEnum.Reference]: "Reference",
    [QuestionnaireItemTypeApiEnum.Text]: "Text",
    [QuestionnaireItemTypeApiEnum.Time]: "Time",
    [QuestionnaireItemTypeApiEnum.Unknown]: "Unknown",
}
