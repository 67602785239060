import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
	standalone: true,
	selector: 'card-container',
	templateUrl: './card-container.component.html',
	styleUrl: './card-container.component.scss',
	imports: [CommonModule, MatCardModule]
})
export class CardContainerComponent {}
