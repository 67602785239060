import { Component, Inject, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { BaseComponent } from '../../../shared';
import {
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef
} from '@angular/material/bottom-sheet';

export interface ConfirmationMessageComponentData {
	title: string | null;
	subtitle: string | null;
	confirmationText: string | null;
	cancelText: string | null;
	confirmationCallback: () => void | null;
	cancelCallback: () => void | null;
}

@Component({
	standalone: true,
	selector: 'confirmation-message',
	templateUrl: './confirmation-message.component.html',
	styleUrl: './confirmation-message.component.scss',
	imports: [MatButton]
})
export class ConfirmationMessageComponent extends BaseComponent {
	@Input()
	public data: ConfirmationMessageComponentData | null = null;

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) _data: ConfirmationMessageComponentData,
		private _bottomSheetRef: MatBottomSheetRef<ConfirmationMessageComponent>
	) {
		super();

		if (_data) {
			this.data = _data;
		}
	}

	public confirm(): void {
		if (this.data?.confirmationCallback) {
			this.data.confirmationCallback();
		}

		this._bottomSheetRef.dismiss();
	}

	public close(): void {
		if (this.data?.cancelCallback) {
			this.data.cancelCallback();
		}

		this._bottomSheetRef.dismiss();
	}
}
