import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
	standalone: true,
	selector: 'error-page',
	templateUrl: './error-page.component.html',
	styleUrl: './error-page.component.scss',
	imports: [MatButton, RouterLink, MatIconModule]
})
export class ErrorPageComponent {
	@Input()
	public errorTitle: string | null = null;

	@Input()
	public errorMessage: string | null = null;

	@Input()
	public errorIcon: string | null = null;

	@Input()
	public navigationButtonText: string | null = null;

	@Input()
	public navigationButtonRoute: string | null = null;
}
