import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mfaCodeValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const mfaCodePattern = /^\d{6}$/;
		if (!control.value || mfaCodePattern.test(control.value)) {
			return null;
		} else {
			return { invalidMFACode: true };
		}
	};
}
