<form [formGroup]="formGroup">
	<auth-card [headerText]="(authService.$tenant | async)?.name ?? null" showInstructionsTextContent>
		<ng-container instructions-text-content> Select an option to receive an MFA code. </ng-container>
		<ng-container card-content>
			<select-input-form-field label="MFA Option" [options]="mfaOptions" [formCtrl]="mfaOptionIdCtrl">
			</select-input-form-field>

			@if (($mfaRequestSent | async) === true || ($selectedMFAOption | async) === mfaOption.TOTP) {
				<mfa-code-input-form-field label="MFA Code" [formCtrl]="mfaCodeCtrl"></mfa-code-input-form-field>
			}
		</ng-container>

		<ng-container card-actions>
			@if (
				((($selectedMFAOption | async) === mfaOption.SMS ||
					($selectedMFAOption | async) === mfaOption.PhoneCall) &&
					($mfaRequestSent | async) === true) ||
				($selectedMFAOption | async) === mfaOption.TOTP
			) {
				<button
					type="submit"
					[disabled]="isProcessing$ | async"
					mat-flat-button
					cdkFocusInitial
					(click)="loginMFA()">
					Submit
				</button>
			}

			@if (
				($selectedMFAOption | async) === mfaOption.PhoneCall || ($selectedMFAOption | async) === mfaOption.SMS
			) {
				@if (($mfaRequestSent | async) === false) {
					<button
						type="submit"
						[disabled]="isProcessing$ | async"
						mat-flat-button
						cdkFocusInitial
						(click)="requestMFACode()">
						Request Code
					</button>
				} @else {
					<button [disabled]="isProcessing$ | async" mat-button cdkFocusInitial (click)="requestMFACode()">
						Request Another Code
					</button>
				}
			}

			<!-- @if (($selectedMFAType | async) === mfaType.Auth) {
			<button
				[disabled]="isProcessing$ | async"
				mat-flat-button
				cdkFocusInitial
				(click)="requestMFACode()">
				Send Request
			</button>
		} -->

			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Back to Login</a
			>
		</ng-container>
	</auth-card>
</form>
