import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	EditUserDetailSettingsResponseApiModel,
	UserDetailSettingsApiModel,
	UserDetailSettingsBaseDataApiModel
} from '../../generated-models';
import { NotificationService } from '../../layout';
import { BaseService } from '../../shared';

@Injectable({
	providedIn: 'root'
})
export class UserDetailSettingsService extends BaseService<UserDetailSettingsBaseDataApiModel> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings', _httpClient, _notificationService);
	}

	public loadUserDetails(): Observable<HttpResponse<UserDetailSettingsApiModel>> {
		return this.get<UserDetailSettingsApiModel>('detail', 'Failed to retrieve the user details.');
	}

	public editUserDetails(response: EditUserDetailSettingsResponseApiModel): Observable<HttpResponse<void>> {
		return this.post<EditUserDetailSettingsResponseApiModel, void>(
			'detail',
			'Failed to edit the user details.',
			response
		);
	}
}
