import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function colorHexValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const colorHexPattern = /^#([A-Fa-f0-9]{6})$/;
		if (!control.value || colorHexPattern.test(control.value)) {
			return null;
		} else {
			return { invalidColorHex: true };
		}
	};
}
