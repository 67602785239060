import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { BaseService } from '..';
import {
	FacilityRoomApiModel,
	MoveEncounterRoomApiModel,
	SaveEncounterNotesApiModel,
	SaveNoteApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';

@Injectable({
	providedIn: 'root'
})
export class EncounterService extends BaseService<void> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderService: AppLoaderService
	) {
		super('encounter', _httpClient, _notificationService);
	}

	public moveRoom(encounterId: string, room: FacilityRoomApiModel): Observable<HttpResponse<void>> {
		const processId = this._appLoaderService.startLoadProcess();
		const model: MoveEncounterRoomApiModel = {
			encounterId: encounterId,
			roomFhirId: room.fhirLocationId,
			roomFhirDisplay: room.label ?? ''
		};
		return this.post<MoveEncounterRoomApiModel, void>(`move-room`, 'Failed to move rooms.', model).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public saveEncounterNotes(
		encounterId: string,
		patientId: string,
		notes: SaveNoteApiModel[]
	): Observable<HttpResponse<void>> {
		const processId = this._appLoaderService.startLoadProcess();
		const model: SaveEncounterNotesApiModel = {
			encounterId: encounterId,
			patientId: patientId,
			notes: notes
		};
		return this.post<SaveEncounterNotesApiModel, void>(
			`save-notes`,
			'Failed to save encounter notes.',
			model
		).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}
}
