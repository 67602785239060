export function pluralize(word: string): string {
	const irregularNouns: { [key: string]: string } = {
		child: 'children',
		person: 'people',
		mouse: 'mice',
		goose: 'geese',
		tooth: 'teeth',
		foot: 'feet',
		leaf: 'leaves',
		life: 'lives',
		man: 'men',
		woman: 'women'
	};

	if (irregularNouns[word]) {
		return irregularNouns[word];
	}

	const lowerWord = word.toLowerCase();

	if (
		lowerWord.endsWith('s') ||
		lowerWord.endsWith('x') ||
		lowerWord.endsWith('z') ||
		lowerWord.endsWith('ch') ||
		lowerWord.endsWith('sh')
	) {
		return word + 'es'; // Add 'es' for words ending in s, x, z, ch, or sh.
	} else if (lowerWord.endsWith('y') && !/[aeiou]/.test(lowerWord[lowerWord.length - 2])) {
		return word.slice(0, -1) + 'ies'; // Replace 'y' with 'ies' if preceded by a consonant.
	} else {
		return word + 's'; // Add 's' for all other cases.
	}
}
