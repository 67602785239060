import { SortDirection } from '@angular/material/sort';

export function sortStringHelper(
	a: string | null | undefined,
	b: string | null | undefined,
	direction: SortDirection
): number {
	if (!a || !b) {
		return 0;
	}

	if (a < b) {
		return direction === 'asc' ? -1 : 1;
	}

	if (a > b) {
		return direction === 'asc' ? 1 : -1;
	}

	return 0;
}

export function sortBooleanHelper(
	a: boolean | null | undefined,
	b: boolean | null | undefined,
	direction: SortDirection
): number {
	if (!a || !b) {
		return 0;
	}

	if (a < b) {
		return direction === 'asc' ? -1 : 1;
	}

	if (a > b) {
		return direction === 'asc' ? 1 : -1;
	}

	return 0;
}
