import { Injectable } from '@angular/core';
import { AuthService } from '../../auth';
import {
	ChiefComplaintRowApiModel,
	EncounterApiModel,
	EncounterLocationApiModel,
	ReadNotesApiModel
} from '../../generated-models';
import { SiteWideDataService } from './site-wide-data-service.service';

import * as signalR from '@microsoft/signalr';

// These must match the names of the C# signal R endpoints exactly
const ROOM_UPDATE = 'EncounterRoomUpdatedAsync';
const NOTE_UPDATE = 'EncounterNotesUpdatedAsync';
const CHIEF_COMPLAINT_UPDATE = 'EncounterChiefComplaintsUpdatedAsync';
const ENCOUNTER_UPSERT = 'AddOrUpdateEncounterAsync';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	public connection: signalR.HubConnection;

	constructor(
		private readonly _siteWideDataService: SiteWideDataService,
		readonly _authService: AuthService
	) {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${_authService.apiBaseEndPoint}/messageHub`)
			.withAutomaticReconnect()
			.build();
	}

	public startListening() {
		this.connection.start();

		this.connection.on(ROOM_UPDATE, (encounterId: string, room: EncounterLocationApiModel) => {
			this._siteWideDataService.UpdateEncounterRoom(encounterId, room);
		});

		this.connection.on(NOTE_UPDATE, (encounterId: string, notes: ReadNotesApiModel[]) => {
			this._siteWideDataService.UpdateEncounterNotes(encounterId, notes);
		});

		this.connection.on(
			CHIEF_COMPLAINT_UPDATE,
			(encounterId: string, chiefComplaints: ChiefComplaintRowApiModel[]) => {
				this._siteWideDataService.UpdateEncounterChiefComplaints(encounterId, chiefComplaints);
			}
		);

		this.connection.on(ENCOUNTER_UPSERT, (encounter: EncounterApiModel) => {
			this._siteWideDataService.AddOrUpdateEncounter(encounter);
		});
	}
}
