import { Component, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

import { BaseComponent } from '../../shared';
import { ConfirmationService } from './confirmation.service';
import { filter, takeUntil, tap } from 'rxjs';
import { ConfirmationMessageComponent, ConfirmationMessageComponentData } from './confirmation-message';

@Component({
	selector: 'confirmation',
	template: '',
	styles: [],
	standalone: true,
	imports: [MatButtonModule, MatBottomSheetModule, MatListModule]
})
export class ConfirmationComponent extends BaseComponent implements OnInit {
	constructor(
		private _confirmationService: ConfirmationService,
		private _bottomSheet: MatBottomSheet
	) {
		super();
	}

	public ngOnInit(): void {
		this._confirmationService.confirmationQueueCount$
			.pipe(
				takeUntil(this._destroy),
				filter((count: number) => count > 0),
				tap(() => {
					const confirmation: ConfirmationMessageComponentData | null =
						this._confirmationService.getNextConfirmationFromQueue();

					if (confirmation) {
						this.showConfirmation(confirmation);
					}
				})
			)
			.subscribe();
	}

	private showConfirmation(data: ConfirmationMessageComponentData): void {
		this._bottomSheet.open(ConfirmationMessageComponent, {
			data: data,
			panelClass: 'confirmation-dialog'
		});
	}
}
