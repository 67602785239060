<form [formGroup]="formGroup">
	<auth-card
		[headerText]="(authService.$tenant | async)?.name ?? null"
		headerSubText="Setup Phone MFA"
		showInstructionsTextContent>
		<ng-container instructions-text-content>
			You need to validate your phone number to finish setting up, select an option, request a code, and enter
			the code you received.
		</ng-container>
		<ng-container card-content>
			<select-input-form-field label="MFA Option" [options]="mfaOptions" [formCtrl]="mfaOptionIdCtrl">
			</select-input-form-field>

			<mfa-code-input-form-field label="MFA Code" [formCtrl]="mfaCodeCtrl"></mfa-code-input-form-field>
		</ng-container>

		<ng-container card-actions>
			@if (($mfaRequestSent | async) === true) {
				<button
					type="submit"
					[disabled]="isProcessing$ | async"
					mat-flat-button
					cdkFocusInitial
					(click)="validateSetupPhoneNumberMFA()">
					Submit
				</button>
			}

			@if (($mfaRequestSent | async) === false) {
				<button
					type="submit"
					[disabled]="isProcessing$ | async"
					mat-flat-button
					cdkFocusInitial
					(click)="setupPhoneNumberMFA()">
					Request Code
				</button>
			} @else {
				<button [disabled]="isProcessing$ | async" mat-button cdkFocusInitial (click)="setupPhoneNumberMFA()">
					Request Another Code
				</button>
			}
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Back to Login</a
			>
		</ng-container>
	</auth-card>
</form>
