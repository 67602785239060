import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth-service.service';
import { inject } from '@angular/core';

/** Attaches needed session information to requests when logged in. */
export const authInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const authService = inject(AuthService);

	authService.updateSessionTimeLeftFromCookie();

	req = req.clone({
		withCredentials: true
	});

	return next(req);
};
