import { Directive, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DATA_INJECTION_TOKEN } from '../../../../layout';

@Directive({
	standalone: true,
	selector: 'base-input-form-field'
})
export class BaseInputFormFieldComponent {
	private readonly _defaultErrorLabel: string;
	private _focusInitial: boolean = false;
	private _fullWidth: boolean = false;
	private _reducedHeight: boolean = false;
	private _customErrorLabel: string | null = null;
	private _hideIfDisabled: boolean = false;

	@Input({ required: true })
	public formCtrl!: FormControl;

	@Input()
	public label: string | null | undefined = null;

	@Input()
	set customErrorLabel(value: string) {
		this._customErrorLabel = value;
	}

	@Input()
	set focusInitial(value: BooleanInput) {
		this._focusInitial = coerceBooleanProperty(value);
	}

	get focusInitial(): boolean {
		return this._focusInitial;
	}

	@Input()
	set fullWidth(value: BooleanInput) {
		this._fullWidth = coerceBooleanProperty(value);
	}

	get fullWidth(): boolean {
		return this._fullWidth;
	}

	@Input()
	set short(value: BooleanInput) {
		this._reducedHeight = coerceBooleanProperty(value);
	}

	get short(): boolean {
		return this._reducedHeight;
	}

	@Input()
	set hideIfDisabled(value: BooleanInput) {
		this._hideIfDisabled = coerceBooleanProperty(value);
	}

	get hideIfDisabled(): boolean {
		return this._hideIfDisabled;
	}

	@Input()
	public customErrors: { type: string; message: string }[] = [];

	get errorLabelToLowerExceptFirst(): string | null {
		return this.convertToLowerExceptFirst(this.errorLabel());
	}

	get errorLabelToLower(): string | null {
		return this.convertToLowerExceptFirst(this.errorLabel());
	}

	get showFormField(): boolean {
		if (this.hideIfDisabled && this.formCtrl.disabled) {
			return false;
		}

		return true;
	}

	private errorLabel(): string {
		if (this.label && !this._customErrorLabel) {
			return this.convertToLowerExceptFirst(this.label);
		} else if (this._customErrorLabel) {
			return this._customErrorLabel;
		} else {
			return this._defaultErrorLabel;
		}
	}

	private convertToLowerExceptFirst(value: string): string {
		if (value.length === 0) {
			return value;
		}
		const firstChar = value.charAt(0);
		const restOfString = value.slice(1).toLowerCase();
		return firstChar + restOfString;
	}

	constructor(@Inject(DATA_INJECTION_TOKEN) readonly defaultErrorLabel: string) {
		this._defaultErrorLabel = defaultErrorLabel;
	}
}
