import { Pipe, PipeTransform } from '@angular/core';
import { booleanFormatter } from '../formatters';
@Pipe({
	name: 'boolean',
	standalone: true
})
export class BooleanPipe implements PipeTransform {
	transform(value: boolean, trueValue: string = 'Yes', falseValue: string = 'No'): string {
		return booleanFormatter(value, trueValue, falseValue);
	}
}
