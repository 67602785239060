<mat-card appearance="outlined">
	<mat-card-header>
		<div class="header-text-container">
			<span class="header-text">{{ headerText }}</span>

			@if (headerSubText) {
				<div class="header-sub-text">{{ headerSubText }}</div>
			}

			@if (showInstructionsTextContent) {
				<div class="instructions-text">
					<ng-content select="[instructions-text-content]"></ng-content>
				</div>
			}
		</div>
	</mat-card-header>
	<mat-card-content>
		<ng-content select="[card-content]"></ng-content>
	</mat-card-content>
	<mat-card-actions>
		<ng-content select="[card-actions]"></ng-content>
		<div class="info-container">
			<span>InSyts EMR © UI v{{ uiVersion }}</span>
		</div>
	</mat-card-actions>
</mat-card>
