<form [formGroup]="formGroup" (ngSubmit)="login()">
	<auth-card [headerText]="(authService.$tenant | async)?.name ?? null" headerSubText="Login">
		<ng-container card-content>
			<text-input-form-field
				label="Email"
				[formCtrl]="usernameCtrl"
				fullWidth
				focusInitial></text-input-form-field>
			<password-input-form-field
				label="Password"
				[formCtrl]="passwordCtrl"
				fullWidth></password-input-form-field>
		</ng-container>

		<ng-container card-actions>
			<button [disabled]="isProcessing$ | async" mat-flat-button type="submit" (click)="login()">Login</button>
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/forgot-password'"
				>Forgot Password</a
			>
		</ng-container>
	</auth-card>
</form>
