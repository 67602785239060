import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
	standalone: true,
	selector: 'base-component'
})
export abstract class BaseComponent implements OnDestroy {
	protected _destroy: Subject<void> = new Subject();

	ngOnDestroy(): void {
		this._destroy.complete();
	}
}
