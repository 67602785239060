import { NotificationTypeEnum } from './notification-type.enum';

interface NotificationType {
	notificationType: NotificationTypeEnum;
	messageTitle: string;
	panelClass: string;
	notificationIcon: string;
}

const NotificationTypes: NotificationType[] = [
	{
		notificationType: NotificationTypeEnum.Error,
		messageTitle: 'Error',
		panelClass: 'notification-toast-error',
		notificationIcon: 'error'
	} as NotificationType,
	{
		notificationType: NotificationTypeEnum.Warning,
		messageTitle: 'Warning',
		panelClass: 'notification-toast-warning',
		notificationIcon: 'warning'
	} as NotificationType,
	{
		notificationType: NotificationTypeEnum.Success,
		messageTitle: 'Success',
		panelClass: 'notification-toast-success',
		notificationIcon: 'check_circle'
	} as NotificationType
];

/** An object for storing and retrieving information about a notification. */
export class Notification {
	private _notificationType: NotificationType | undefined = undefined;

	/** Determines if the notification toast should close automatically. */
	public autoClose: boolean = true;

	/** Determines how long in seconds the notification toast should show before it closes automatically. */
	public successAutoCloseSeconds: number = 3;
	public warningAutoCloseSeconds: number = 3;
	public errorAutoCloseSeconds: number = 10;

	constructor(
		private _type: NotificationTypeEnum,
		private _message: string
	) {
		this._notificationType = NotificationTypes.find(f => f.notificationType === this._type) ?? undefined;
	}

	get message(): string {
		return this._message;
	}

	/** the panel class to use for the chosen notification type. */
	get panelClass(): string | undefined {
		return this._notificationType?.panelClass ?? undefined;
	}

	get messageTitle(): string | null {
		return this._notificationType?.messageTitle ?? null;
	}

	/** The icon to use for the chosen notification type. */
	get notificationIcon(): string | null {
		return this._notificationType?.notificationIcon ?? null;
	}

	get notificationType(): NotificationType | undefined {
		return this._notificationType;
	}
}
