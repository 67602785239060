import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSegment } from '@angular/router';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs';
import { AuthService } from '../../auth';
import { UserInfoApiModel } from '../../generated-models';
import { CurrentEncounterNotesComponent } from '../../notes/notes.component';
import { BaseComponent, HasClaimDirective, SecurityService } from '../../shared';
import { DefaultPipe } from '../../shared/pipes/default.pipe';
import { FhirService } from '../../shared/services/fhir-service.service';
import { SiteWideDataService } from '../../shared/services/site-wide-data-service.service';
import { AppSidenavConfig, AppSidenavService } from '../app-sidenav';
import { ChangeFacilityComponent } from '../change-facility/change-facility.component';
import { Breadcrumb } from './models/breadcrumb.model';
@Component({
	standalone: true,
	selector: 'app-nav-bar',
	templateUrl: './app-nav-bar.component.html',
	styleUrl: './app-nav-bar.component.scss',
	imports: [
		CommonModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatBadgeModule,
		ChangeFacilityComponent,
		CurrentEncounterNotesComponent,
		DefaultPipe,
		HasClaimDirective,
		RouterLink
	],
	providers: [SecurityService]
})
export class AppNavBarComponent extends BaseComponent {
	@Output() menuClick: EventEmitter<void> = new EventEmitter();

	public hasSandboxAccess: boolean = true;

	public breadcrumbs$ = this._router.events.pipe(
		takeUntil(this._destroy),
		filter(event => event instanceof NavigationEnd),
		distinctUntilChanged(),
		map(() => {
			return this.buildBreadCrumb(this._activatedRoute.root);
		})
	);

	public userInfo = computed<UserInfoApiModel | null>(() => {
		const userInfo = this.authService.userInfo();

		this._securityService.claims = userInfo?.claims ?? [];
		this.userName = this.buildName(userInfo);

		return userInfo;
	});
	public userName: string | null = null;
	public selectedPatientEncounter = this.siteWideDataService.selectedEncounter;

	public visitNumberDisplay = computed<string>(() => {
		const encounter = this.selectedPatientEncounter();
		if (!encounter) {
			return '';
		}

		if (encounter.visitNumber) {
			return encounter.visitNumber;
		}

		// If we don't have a VN, we will use the Encounter ID
		// It should be in the form of a GUID
		// The following code will display
		// this: 				1f1bbe48-8272...
		// instead of this: 	1f1bbe48-8272-4c38-bc06-1259d0bea2ea
		const maxLen = 13;
		return encounter.id.length > maxLen ? `${encounter.id.substring(0, maxLen)}...` : encounter.id;
	});

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _appSidenavService: AppSidenavService,
		private _securityService: SecurityService,
		public authService: AuthService,
		public fhirService: FhirService,
		public siteWideDataService: SiteWideDataService
	) {
		super();
	}

	onCreateNewPatient(): void {
		//this.fhirService.createPatientFromMessage(convertRequest).subscribe(resp => console.log(resp));
	}

	public changeFacility() {
		const sidenavData = null;

		const appSidenavConfig: AppSidenavConfig = {
			title: 'Change Facility',
			onCloseCallback: () => {}
		};

		this._appSidenavService.showSidenav(ChangeFacilityComponent, sidenavData, appSidenavConfig);
	}

	public logout(): void {
		this.authService.logout().pipe(take(1)).subscribe();
	}

	private buildBreadCrumb(routeRoot: ActivatedRoute): Breadcrumb[] {
		const newBreadcrumbs: Breadcrumb[] = [];
		let url: string = '';

		let currentRoute: ActivatedRoute | null = routeRoot;

		do {
			const childrenRoutes = currentRoute.children;
			currentRoute = null;
			childrenRoutes.forEach((route: ActivatedRoute) => {
				if (route.outlet === 'primary') {
					const routeSnapshot = route.snapshot;

					const label: string = route.routeConfig?.data ? route.routeConfig.data['breadcrumb'] : null;

					url += '/' + routeSnapshot.url.map((segment: UrlSegment) => segment.path).join('/');

					if (label && url) {
						newBreadcrumbs.push({
							label: label,
							url: url
						} as Breadcrumb);
					}

					currentRoute = route;
				}
			});
		} while (currentRoute);

		return newBreadcrumbs;
	}

	private buildName(userInfo: UserInfoApiModel | null): string {
		return `${userInfo?.title ? `${userInfo.title} ` : ''}${userInfo?.firstName} ${userInfo?.lastName}`;
	}
}
