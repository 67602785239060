import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { take, takeUntil, tap } from 'rxjs';
import { ForgotPasswordRequestApiModel } from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import { BaseIsProcessingComponent, TextInputFormFieldComponent } from '../../shared';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrl: './forgot-password.component.scss',
	imports: [
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		forwardRef(() => TextInputFormFieldComponent)
	]
})
export class ForgotPasswordComponent extends BaseIsProcessingComponent {
	public requestCount: number = 0;

	public formGroup: FormGroup = new FormGroup({
		email: new FormControl<string | null>(null, [Validators.required, Validators.email])
	});

	get emailCtrl() {
		return this.formGroup.get('email') as FormControl;
	}

	constructor(
		public readonly authService: AuthService,
		readonly _appLoaderService: AppLoaderService,
		private readonly _notificationService: NotificationService
	) {
		super([authService.isProcessing$]);

		const processId = 'forgot-password-load-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();
	}

	public forgotPassword(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const forgotPasswordRequest: ForgotPasswordRequestApiModel = {
				email: this.emailCtrl.value,
				tenantCode: this.authService.tenantCode!
			};

			this.authService
				.forgotPassword(forgotPasswordRequest)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this.requestCount++;
							this._notificationService.showSuccessNotification(
								'A link to reset your password will be sent if this account exists, please check you email.'
							);
						}
					})
				)
				.subscribe();
		}
	}
}
