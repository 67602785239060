import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { merge, take, takeUntil, tap } from 'rxjs';
import { ResetPasswordRequestApiModel } from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import {
	BaseIsProcessingComponent,
	PasswordInputFormFieldComponent,
	TextInputFormFieldComponent
} from '../../shared';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	selector: 'reset-password',
	templateUrl: './reset-password.component.html',
	styleUrl: './reset-password.component.scss',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		forwardRef(() => TextInputFormFieldComponent),
		PasswordInputFormFieldComponent
	]
})
export class ResetPasswordComponent extends BaseIsProcessingComponent implements OnInit {
	public formGroup: FormGroup = new FormGroup({
		email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
		resetCode: new FormControl<string | null>(null, [Validators.required]),
		newPassword: new FormControl<string | null>(null, [Validators.required]),
		verifyNewPassword: new FormControl<string | null>(null, [Validators.required])
	});

	get emailCtrl() {
		return this.formGroup.get('email') as FormControl;
	}

	get resetCodeCtrl() {
		return this.formGroup.get('resetCode') as FormControl;
	}

	get newPasswordCtrl() {
		return this.formGroup.get('newPassword') as FormControl;
	}

	get verifyNewPasswordCtrl() {
		return this.formGroup.get('verifyNewPassword') as FormControl;
	}

	constructor(
		public readonly authService: AuthService,
		private readonly _route: ActivatedRoute,
		readonly _appLoaderService: AppLoaderService,
		private readonly _notificationService: NotificationService,
		private readonly _router: Router
	) {
		super([authService.isProcessing$]);

		const processId = 'reset-password-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();

		merge(this.newPasswordCtrl.valueChanges, this.verifyNewPasswordCtrl.valueChanges)
			.pipe(
				takeUntil(this._destroy),
				tap(() => {
					if (this.newPasswordCtrl.value === this.verifyNewPasswordCtrl.value) {
						if (this.newPasswordCtrl?.errors && this.newPasswordCtrl.hasError('passwordsDoNotMatch')) {
							delete this.newPasswordCtrl.errors['passwordsDoNotMatch'];
							this.newPasswordCtrl.updateValueAndValidity({ emitEvent: false });
						}

						if (this.verifyNewPasswordCtrl?.errors && this.verifyNewPasswordCtrl.hasError('passwordsDoNotMatch')) {
							delete this.verifyNewPasswordCtrl.errors['passwordsDoNotMatch'];
							this.verifyNewPasswordCtrl.updateValueAndValidity({ emitEvent: false });
						}
					} else {
						this.newPasswordCtrl.setErrors({ passwordsDoNotMatch: true }, { emitEvent: false });
						this.verifyNewPasswordCtrl.setErrors({ passwordsDoNotMatch: true }, { emitEvent: false });
					}
				})
			)
			.subscribe();

		const email = this._route.parent?.snapshot.queryParamMap.get('email');

		if (email) {
			this.emailCtrl.setValue(email, { emitEvent: false });
		}

		const resetCode = this._route.parent?.snapshot.queryParamMap.get('resetCode');

		if (resetCode) {
			this.resetCodeCtrl.setValue(resetCode, { emitEvent: false });
		} else {
			this._router.navigate(['/page-not-found']);
		}
	}

	public ngOnInit(): void {
		const email = this._route.snapshot.queryParamMap.get('email');
		const resetCode = this._route.snapshot.queryParamMap.get('resetCode');

		this.formGroup.patchValue(
			{
				email: email,
				resetCode: resetCode
			},
			{ emitEvent: false }
		);

		const passwordMinLength = this.authService.baseData!.passwordMinLength!;
		const passwordMaxLength = this.authService.baseData!.passwordMaxLength!;

		this.newPasswordCtrl.addValidators([
			Validators.minLength(passwordMinLength),
			Validators.maxLength(passwordMaxLength)
		]);
		this.newPasswordCtrl.updateValueAndValidity({ emitEvent: false });
		this.verifyNewPasswordCtrl.addValidators([
			Validators.minLength(passwordMinLength),
			Validators.maxLength(passwordMaxLength)
		]);
		this.verifyNewPasswordCtrl.updateValueAndValidity({ emitEvent: false });
	}

	public resetPassword(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const resetPasswordRequest: ResetPasswordRequestApiModel = {
				email: this.emailCtrl.value,
				tenantCode: this.authService.tenantCode!,
				resetCode: this.resetCodeCtrl.value,
				newPassword: this.newPasswordCtrl.value
			};

			this.authService
				.resetPassword(resetPasswordRequest)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this._notificationService.showSuccessNotification('Your password has been successfully reset.');

							this._router.navigate([`${this.authService.tenantCode}/auth/login`]);
						}
					})
				)
				.subscribe();
		}
	}
}
