import { Component, computed, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { tap } from 'rxjs';
import { EncounterApiModel, FacilityRoomApiModel } from '../../../../generated-models';
import { EncounterService } from '../../../services/encounter.service';
import { SiteWideDataService } from '../../../services/site-wide-data-service.service';

interface RoomListHelper {
	room: FacilityRoomApiModel;
	id: number;
	enabled: boolean;
	icon?: string | null;
	label?: string | null;
}

export interface ChangeRoomBottomSheetComponentData {
	rooms: FacilityRoomApiModel[];
	row: EncounterApiModel;
}

@Component({
	selector: 'change-room-bottom-sheet',
	templateUrl: 'change-room-bottom-sheet.html',
	standalone: true,
	imports: [MatListModule, MatIconModule]
})
export class ChangeRoomBottomSheetComponent {
	@Input()
	public data: ChangeRoomBottomSheetComponentData | null = null;

	public roomListHelper = computed<RoomListHelper[]>(() => {
		const dashboardSignal = this._siteWideDataService.getCurrentDashboardSignal();
		const currentEncounters = dashboardSignal();
		const inWaitingRoom = this.data?.row.currentLocation?.isWaitingRoom;
		const inUseRoomIds: string[] = currentEncounters.map(row => row?.currentLocation?.locationId ?? '');

		return (
			this.data?.rooms?.map(room => {
				const isWaitingRoomOption = room.roomType.label?.toLocaleLowerCase().includes('waiting');
				return {
					room: room,
					id: room.id,
					label: room.label,
					icon: room.roomType.matIcon,
					enabled: !inUseRoomIds.includes(room.fhirLocationId) || (!!isWaitingRoomOption && !inWaitingRoom)
				};
			}) ?? []
		);
	});

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) _data: ChangeRoomBottomSheetComponentData,
		private _bottomSheetRef: MatBottomSheetRef<ChangeRoomBottomSheetComponent>,
		private _siteWideDataService: SiteWideDataService,
		private _encounterService: EncounterService
	) {
		if (_data) {
			this.data = _data;
		}
	}

	public confirm(): void {
		this._bottomSheetRef.dismiss();
	}

	public close(): void {
		this._bottomSheetRef.dismiss();
	}

	public changePatientRoom(room: FacilityRoomApiModel): void {
		const row = this.data?.row;

		this._encounterService
			.moveRoom(row?.id ?? '', room)
			.pipe(
				tap(response => {
					if (response.ok) {
						this.close();
					}
				})
			)
			.subscribe();
	}
}
