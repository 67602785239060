import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { pipe, takeUntil, tap } from 'rxjs';
import { LoginRequestApiModel } from '../../generated-models';
import { AppLoaderService } from '../../layout';
import { BaseIsProcessingComponent, TextInputFormFieldComponent } from '../../shared';
import { PasswordInputFormFieldComponent } from '../../shared/components/input-form-fields/password-input-form-field';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrl: './login.component.scss',
	imports: [
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		TextInputFormFieldComponent,
		PasswordInputFormFieldComponent
	]
})
export class LoginComponent extends BaseIsProcessingComponent implements OnInit {
	public formGroup: FormGroup = new FormGroup({
		username: new FormControl<string | null>(null, [Validators.required, Validators.email]),
		password: new FormControl<string | null>(null, [Validators.required])
	});

	get usernameCtrl() {
		return this.formGroup.get('username') as FormControl;
	}

	get passwordCtrl() {
		return this.formGroup.get('password') as FormControl;
	}

	constructor(
		public readonly authService: AuthService,
		private readonly _router: Router,
		readonly _appLoaderService: AppLoaderService
	) {
		super([authService.isProcessing$]);

		const processId = 'login-component-load-process-id';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();
	}

	public ngOnInit(): void {
		this.authService.clearMFARequired();

		this.authService.$MFARequired
			.pipe(
				takeUntil(this._destroy),
				pipe(
					tap(loginRequest => {
						if (loginRequest) {
							this._router.navigate([`/${this.authService.tenantCode}/auth/mfa`], {
								state: {
									username: loginRequest.email,
									password: loginRequest.password,
									mfaOptions: this.authService.mfaOptions()
								}
							});

							this.formGroup.reset({ emitEvent: false });
						}
					})
				)
			)
			.subscribe();
	}

	public login(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid && this.authService.tenantCode) {
			const loginRequest: LoginRequestApiModel = {
				email: this.usernameCtrl.value,
				password: this.passwordCtrl.value,
				tenantCode: this.authService.tenantCode
			};

			this.authService
				.login(loginRequest, () => {
					this._router.navigate([`${this.authService.tenantCode}`]);
				})
				.subscribe();
		}
	}
}
