/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CancelOrderReasonApiEnum {
    Revoked = 0,
    EnteredInError = 1,
}

export const CancelOrderReasonApiEnumDisplayMap : { [key in CancelOrderReasonApiEnum] : string } = {
    [CancelOrderReasonApiEnum.Revoked]: "Revoked",
    [CancelOrderReasonApiEnum.EnteredInError]: "EnteredInError",
}
