import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
	selector: 'readonly-display-input',
	standalone: true,
	imports: [MatInputModule, CommonModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule],
	templateUrl: './readonly-display-input.component.html',
	styleUrl: './readonly-display-input.component.scss'
})
export class ReadonlyDisplayInputComponent {
	public label = input<string | null | undefined>(null);
	public value = input<string | null | undefined>(null);
	public suffix = input<string | null | undefined>(null);
	public icon = input<string | null | undefined>(null);
}
