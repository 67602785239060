<form [formGroup]="formGroup">
	@if (showGenerateSecretPanel) {
		<div class="qr-code-container">
			<span>Scan this code with your preferred authenticator app.</span>
			<qr-code [value]="secretUri ?? undefined" size="384" errorCorrectionLevel="M" />
			<span
				>Your secret key is <span class="authentication-secret-key">{{ secret }}</span> you can use this if you
				can't scan the QR code.</span
			>
		</div>

		<text-input-form-field
			label="Confirmation Code"
			[formCtrl]="totpConfirmationCodeCtrl"
			fullWidth></text-input-form-field>
	}
	@if (data?.isEdit ?? false) {
		<span
			>You have already registered, you can generate a new secret and revoke your old one if you lost access to
			it.</span
		>
	}
</form>

<div class="action-buttons">
	@if (showGenerateSecretPanel) {
		<button mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
			<mat-icon aria-hidden="false">save</mat-icon>Save
		</button>
	} @else {
		<button mat-flat-button (click)="generateNewSecret()" [disabled]="isProcessing$ | async">
			<mat-icon aria-hidden="false">save</mat-icon>Generate a new secret.
		</button>
	}
</div>
