import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { AuthService } from '../../auth';
import {
	ChiefComplaintRowApiModel,
	EncounterApiModel,
	EncounterLocationApiModel,
	GenderApiEnum,
	PatientApiModel,
	ReadNotesApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import { PatientListService } from '../../patient-list/patient-list.service';
import { BaseService } from './base-service.service';

@Injectable({
	providedIn: 'root'
})
export class SiteWideDataService extends BaseService<void> {
	public selectedEncounter = signal<EncounterApiModel | null>(null);

	public selectedEncounterId = computed<string | null>(() => {
		return this.selectedEncounter()?.id ?? null;
	});

	public selectedPatient = computed<PatientApiModel | null>(() => {
		return this.selectedEncounter()?.patient ?? null;
	});

	public selectedPatientId = computed<string | null>(() => {
		return this.selectedPatient()?.id ?? null;
	});

	public selectedPatientGender = computed<GenderApiEnum.Male | GenderApiEnum.Female>(() => {
		return this.selectedPatient()?.gender == GenderApiEnum.Female ? GenderApiEnum.Female : GenderApiEnum.Male;
	});

	public selectedpatientIsFemale = computed<boolean>(() => {
		return this.selectedPatientGender() == GenderApiEnum.Female;
	});

	public selectedpatientIsMale = computed<boolean>(() => {
		return this.selectedPatientGender() == GenderApiEnum.Male;
	});

	public selectedPatientAge = computed<number | null>(() => {
		const dobStr = this.selectePatientDobString();
		if (!dobStr) {
			return null;
		}
		const dob = dobStr ? new Date(dobStr) : new Date();
		const diffMs = Date.now() - dob.getTime();
		const ageDt = new Date(diffMs);
		const ageInYears = Math.abs(ageDt.getUTCFullYear() - 1970);
		return ageInYears;
	});

	public selectePatientDobString = computed<string | null>(() => {
		const dateString = this.selectedPatient()?.dateOfBirth;
		if (!dateString) {
			return null;
		}
		const datePipe = new DatePipe('en-US');
		return datePipe.transform(dateString, 'MM/dd/yyyy');
	});

	public primaryTenantChiefComplaint = computed<ChiefComplaintRowApiModel | null>(() => {
		return this.selectedEncounter()?.chiefComplaints[0] ?? null;
	});

	public primaryTenantChiefComplaintId = computed(() => {
		return this.primaryTenantChiefComplaint()?.sophosId ?? 0;
	});

	private patientTrackerDataIsLoaded = false;
	private patientTrackerDataPromise: Promise<EncounterApiModel[]> | null = null;
	private patientTrackerData = signal<EncounterApiModel[]>([]);

	constructor(
		private readonly _patientListService: PatientListService,
		private readonly _appLoaderService: AppLoaderService,
		readonly _authService: AuthService,
		_httpClient: HttpClient,
		_notificationService: NotificationService
	) {
		super(null, _httpClient, _notificationService);
	}

	async setSelectedEncounterById(encounterId: string): Promise<WritableSignal<EncounterApiModel | null>> {
		if (this.selectedEncounter()?.id != encounterId) {
			const patientTrackerData = await this.loadPatientListInfoIfNeeded();

			const patientRow = patientTrackerData.find((e: EncounterApiModel) => e.id === encounterId);
			if (patientRow) {
				this.selectedEncounter.set(patientRow);
				return Promise.resolve(this.selectedEncounter);
			}
			throw new Error(`PatientTrackerEncounter row for "${encounterId}" was not found`);
		}
		return Promise.resolve(this.selectedEncounter);
	}

	private async loadPatientListInfoIfNeeded(): Promise<EncounterApiModel[]> {
		if (this.patientTrackerDataPromise !== null) {
			return this.patientTrackerDataPromise;
		}

		this.patientTrackerDataPromise = new Promise(resolve => {
			if (!this.patientTrackerDataIsLoaded) {
				const processId = this._appLoaderService.startLoadProcess();
				this._patientListService
					.getAllPatientsDetailed()
					.pipe(
						tap(response => {
							let patientTrackerData: EncounterApiModel[] = [];
							if (response.ok) {
								const serverData = response.body ?? [];
								if (serverData) {
									this.patientTrackerData.set(serverData);
									patientTrackerData = serverData;
								} else {
									console.error('Dashboard data is null or undefined.');
								}
							}
							this.patientTrackerDataIsLoaded = true;
							resolve(patientTrackerData);
						}),
						finalize(() => {
							this._appLoaderService.completeLoadProcess(processId);
						})
					)
					.subscribe();
			} else {
				resolve(this.patientTrackerData());
			}
		});

		return this.patientTrackerDataPromise;
	}

	getCurrentDashboardSignal(): WritableSignal<EncounterApiModel[]> {
		this.loadPatientListInfoIfNeeded();
		return this.patientTrackerData;
	}

	public AddOrUpdateEncounter(encoutner: EncounterApiModel) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const existingEncounterIndex = old.findIndex((x: EncounterApiModel) => x.id === encoutner.id);

			const next = [...old];
			if (existingEncounterIndex > -1) {
				next[existingEncounterIndex] = encoutner;
			} else {
				next.push(encoutner);
			}

			// if we updated a patient via MRN or Id, we should update those changes too
			next.forEach(item => {
				const newId = encoutner.patient.id;
				const newMRN = encoutner.patient.medicalRecordNumber;
				if ((newId && item.patient.id == newId) || (newMRN && item.patient.medicalRecordNumber == newMRN)) {
					item.patient = encoutner.patient;
				}
			});
			return next;
		});
	}

	public UpdateEncounterChiefComplaints(encounterId: string, chiefComplaints: ChiefComplaintRowApiModel[]) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const updatedEncounter = this.patientTrackerData().find((x: EncounterApiModel) => x.id === encounterId);

			// no change if there is no encounter
			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.chiefComplaints = chiefComplaints;

			return next;
		});

		// Update the selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.chiefComplaints = chiefComplaints;
				return next;
			});
		}
	}

	public UpdateEncounterNotes(encounterId: string, notes: ReadNotesApiModel[]) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const updatedEncounter = this.patientTrackerData().find((x: EncounterApiModel) => x.id === encounterId);

			// no change if there is no encounter
			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.notes = notes;

			return next;
		});

		// Update the selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.notes = notes;
				return next;
			});
		}
	}

	public UpdateEncounterRoom(encounterId: string, room: EncounterLocationApiModel) {
		this.patientTrackerData.update(old => {
			if (!old) {
				return old;
			}
			const updatedEncounter = old.find((x: EncounterApiModel) => x.id === encounterId);

			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.currentLocation = room;

			return next;
		});

		// Update selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.currentLocation = room;
				return next;
			});
		}
	}
}
