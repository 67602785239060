import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatCardModule } from '@angular/material/card';

@Component({
	standalone: true,
	selector: 'action-card',
	templateUrl: './action-card.component.html',
	styleUrl: './action-card.component.scss',
	imports: [CommonModule, MatCardModule]
})
export class ActionCardComponent {
	private _disabled: boolean = false;
	private _noFooterContent: boolean = false;

	@Input()
	public title: string | null = null;

	@Input()
	set disabled(value: BooleanInput) {
		this._disabled = coerceBooleanProperty(value);
	}

	get disabled(): boolean {
		return this._disabled;
	}

	@Input()
	set noFooterContent(value: BooleanInput) {
		this._noFooterContent = coerceBooleanProperty(value);
	}

	get noFooterContent(): boolean {
		return this._noFooterContent;
	}
}
