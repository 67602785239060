import { Directive, Inject, InjectionToken, OnDestroy } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	Subject,
	combineLatest,
	map,
	takeUntil
} from 'rxjs';

export const IS_PROCESSING_INJECTION_TOKEN = new InjectionToken<
	Observable<boolean>[]
>('IS_PROCESSING_INJECTION_TOKEN');

@Directive({
	standalone: true,
	selector: 'base-is-processing-component'
})
export abstract class BaseIsProcessingComponent implements OnDestroy {
	protected _destroy: Subject<void> = new Subject();

	protected readonly _isProcessing: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	/** Determines if the component is processing. */
	public readonly isProcessing$: Observable<boolean> =
		this._isProcessing.asObservable();

	constructor(
		@Inject(IS_PROCESSING_INJECTION_TOKEN)
		isProcessingObservables$: Observable<boolean>[]
	) {
		combineLatest(isProcessingObservables$)
			.pipe(
				takeUntil(this._destroy),
				map((results: boolean[]) => {
					const isProcesing = results.some(Boolean);
					this._isProcessing.next(isProcesing);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this._destroy.complete();
		this._isProcessing.complete();
	}
}
