<div class="notification-message-container">
	<div class="icon-message-title-container">
		<mat-icon aria-hidden="false" class="notification-icon">{{
			data?.notification?.notificationIcon
		}}</mat-icon>
		<span class="message-title">{{ data?.notification?.messageTitle }}:</span>
	</div>
	<span class="message">{{ data?.notification?.message }}</span>
</div>

<div>
	<button mat-icon-button class="close-button" aria-label="close" (click)="close()">
		<mat-icon aria-hidden="false">close</mat-icon>
	</button>
</div>
