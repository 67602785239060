import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseIsProcessingComponent } from '../../shared';
import { AppLoaderDirective } from '../app-loader';

@Component({
	standalone: true,
	selector: 'loading-container',
	templateUrl: './loading-container.component.html',
	styleUrl: './loading-container.component.scss',
	imports: [CommonModule, AppLoaderDirective, MatPaginatorModule, MatProgressSpinnerModule]
})
export class LoadingContainerComponent extends BaseIsProcessingComponent implements OnDestroy {
	@Input()
	set isProcessing(value: boolean | null) {
		this._isProcessing.next(!!value);
	}

	constructor() {
		super([]);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}
