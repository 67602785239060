/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SortDirectionApiEnum {
    None = 0,
    Ascending = 1,
    Descending = 2,
}

export const SortDirectionApiEnumDisplayMap : { [key in SortDirectionApiEnum] : string } = {
    [SortDirectionApiEnum.None]: "None",
    [SortDirectionApiEnum.Ascending]: "Ascending",
    [SortDirectionApiEnum.Descending]: "Descending",
}
