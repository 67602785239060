<table-container [isProcessing]="isProcessing$ | async" [heightOffset]="64 + 48">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
		<ng-container matColumnDef="mfaType">
			<th mat-header-cell *matHeaderCellDef>MFA Type</th>
			<td mat-cell *matCellDef="let row">
				{{ row.mfaTypeName | default }}
			</td>
		</ng-container>

		<ng-container matColumnDef="setup">
			<th mat-header-cell *matHeaderCellDef>Setup</th>
			<td mat-cell *matCellDef="let row">
				{{ row.isSetup | boolean }}
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef>Actions</th>
			<td mat-cell *matCellDef="let row">
				<div class="action-buttons">
					<button [disabled]="isProcessing$ | async" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						@if (!row.isSetup && row.canChange) {
							<button
								mat-menu-item
								color="primary"
								aria-label="Edit phone number."
								(click)="register(row)"
								[disabled]="row.removedOnUtc != null">
								<mat-icon aria-hidden="false">edit</mat-icon>
								<span>Register</span>
							</button>
						}

						@if (row.isSetup && row.canChange) {
							<button
								mat-menu-item
								color="primary"
								aria-label="Edit phone number."
								(click)="change(row)"
								[disabled]="row.removedOnUtc != null">
								<mat-icon aria-hidden="false">edit</mat-icon>
								<span>Change</span>
							</button>
						}

						@if (row.isSetup && row.canRemove) {
							<button mat-menu-item color="primary" aria-label="Remove." (click)="remove(row)">
								<mat-icon aria-hidden="false">delete</mat-icon>
								<span>Remove</span>
							</button>
						}
					</mat-menu>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
		<tr class="mat-row" *matNoDataRow>
			<td class="mat-cell" [attr.colspan]="columnsToDisplay.length">No data.</td>
		</tr>
	</table>

	<!-- <form [formGroup]="formGroup">
		<div class="mfa-types-container">
			<span>MFA Types</span>

			<div class="mfa-phone-number-container" *hasClaim="['UsePhoneNumberMFA']">
				<span>Phone MFA is {{ phoneNumberMFAActiveCtrl.value ? 'Enabled' : 'Disabled' }}</span>
				@if (phoneNumberMFAActiveCtrl.value) {
					<button mat-flat-button (click)="addEditMFAPhoneNumber(true)" [disabled]="isProcessing$ | async">
						<mat-icon aria-hidden="false">phone</mat-icon>Change Phone number
					</button>
				} @else {
					<button mat-flat-button (click)="addEditMFAPhoneNumber(false)" [disabled]="isProcessing$ | async">
						<mat-icon aria-hidden="false">phone</mat-icon>Register Phone Number
					</button>
				}
			</div>

			<div class="mfa-totp-container" *hasClaim="['UseTOTPMFA']">
				<span><mat-icon></mat-icon>Phone MFA is {{ totpMFAActiveCtrl.value ? 'Enabled' : 'Disabled' }}</span>
				@if (totpMFAActiveCtrl.value) {
					<button
						*hasClaim="['CanChangeTOTPMFA']"
						mat-flat-button
						(click)="addEditMFATOTP(true)"
						[disabled]="isProcessing$ | async">
						<mat-icon aria-hidden="false">phonelink_lock</mat-icon>Change TOTP
					</button>
				} @else {
					<button mat-flat-button (click)="addEditMFATOTP(false)" [disabled]="isProcessing$ | async">
						<mat-icon aria-hidden="false">phonelink_lock</mat-icon>Register New TOTP
					</button>
				}
			</div>
		</div>

		<ng-container container-action-content> </ng-container>
	</form> -->
</table-container>
