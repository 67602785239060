/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EncounterStatusApiEnum {
    Arrived = 0,
    Triaged = 1,
    InProgress = 2,
    Planned = 3,
    EnteredInError = 4,
    Onleave = 5,
    Cancelled = 6,
    Finished = 7,
    Unknown = 8,
}

export const EncounterStatusApiEnumDisplayMap : { [key in EncounterStatusApiEnum] : string } = {
    [EncounterStatusApiEnum.Arrived]: "Arrived",
    [EncounterStatusApiEnum.Triaged]: "Triaged",
    [EncounterStatusApiEnum.InProgress]: "InProgress",
    [EncounterStatusApiEnum.Planned]: "Planned",
    [EncounterStatusApiEnum.EnteredInError]: "EnteredInError",
    [EncounterStatusApiEnum.Onleave]: "Onleave",
    [EncounterStatusApiEnum.Cancelled]: "Cancelled",
    [EncounterStatusApiEnum.Finished]: "Finished",
    [EncounterStatusApiEnum.Unknown]: "Unknown",
}
