/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CustomInputControlTypeApiEnum {
    Temperature = 0,
    PainLevel = 1,
    EmergencySeverityIndex = 2,
    IllicitDrugs = 3,
    BodyMeasurement = 4,
    BMI = 5,
    Height = 6,
    Weight = 7,
}

export const CustomInputControlTypeApiEnumDisplayMap : { [key in CustomInputControlTypeApiEnum] : string } = {
    [CustomInputControlTypeApiEnum.Temperature]: "Temperature",
    [CustomInputControlTypeApiEnum.PainLevel]: "PainLevel",
    [CustomInputControlTypeApiEnum.EmergencySeverityIndex]: "EmergencySeverityIndex",
    [CustomInputControlTypeApiEnum.IllicitDrugs]: "IllicitDrugs",
    [CustomInputControlTypeApiEnum.BodyMeasurement]: "BodyMeasurement",
    [CustomInputControlTypeApiEnum.BMI]: "BMI",
    [CustomInputControlTypeApiEnum.Height]: "Height",
    [CustomInputControlTypeApiEnum.Weight]: "Weight",
}
