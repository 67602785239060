import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	selector: 'password-input-form-field',
	templateUrl: './password-input-form-field.component.html',
	styleUrl: './password-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatIconModule]
})
export class PasswordInputFormFieldComponent extends BaseInputFormFieldComponent {
	private _isPasswordVisible = false;
	private _autocomplete: string = 'on';
	private _showVisibilityButton = false;
	private _disableCopyPaste = false;

	public maxLength = input<number>();

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	set autoCompleteOff(value: BooleanInput) {
		if (coerceBooleanProperty(value)) {
			this._autocomplete = 'off';
		}
	}

	get autoComplete(): string {
		return this._autocomplete;
	}

	@Input()
	set disableCopyPaste(value: BooleanInput) {
		this._disableCopyPaste = coerceBooleanProperty(value);
	}

	get disableCopyPaste(): boolean {
		return this._disableCopyPaste;
	}

	@Input()
	set showVisibilityButton(value: BooleanInput) {
		this._showVisibilityButton = coerceBooleanProperty(value);
	}

	get showVisibilityButton(): boolean {
		return this._showVisibilityButton;
	}

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	constructor() {
		super('The passsword');
	}

	public togglePasswordVisibility(event: Event): void {
		event.stopPropagation();
		this._isPasswordVisible = !this._isPasswordVisible;
	}
}
