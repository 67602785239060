import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'info-item-section',
	templateUrl: './info-item-section.component.html',
	styleUrl: './info-item-section.component.scss',
	imports: [CommonModule]
})
export class InfoItemSectionComponent {
	@Input()
	public label: string | null = null;
}
