<form [formGroup]="formGroup">
	<auth-card [headerText]="(authService.$tenant | async)?.name ?? null" headerSubText="Password Expired">
		<ng-container card-content>
			<password-input-form-field
				label="Old Password"
				[formCtrl]="oldPasswordCtrl"
				autoCompleteOff></password-input-form-field>
			<password-input-form-field
				label="New Password"
				[formCtrl]="newPasswordCtrl"
				autoCompleteOff
				disableCopyPaste
				showVisibilityButton></password-input-form-field>
			<password-input-form-field
				label="Verify New Password"
				[formCtrl]="verifyNewPasswordCtrl"
				autoCompleteOff
				disableCopyPaste
				showVisibilityButton
				[customErrors]="[
					{ type: 'passwordsDoNotMatch', message: 'The passwords do not match.' }
				]"></password-input-form-field>
		</ng-container>

		<ng-container card-actions>
			<button
				type="submit"
				[disabled]="isProcessing$ | async"
				mat-flat-button
				cdkFocusInitial
				(click)="changePassword()">
				Change Password
			</button>
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Back to Login</a
			>
		</ng-container>
	</auth-card>
</form>
