import { Component, Inject, Input } from '@angular/core';
import {
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetModule,
	MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Notification } from '../models';

export interface NotificationtToastMessageComponentData {
	notification: Notification;
}

@Component({
	selector: 'notification-toast-message',
	templateUrl: './notification-toast-message.component.html',
	styleUrl: './notification-toast-message.component.scss',
	standalone: true,
	imports: [MatButtonModule, MatBottomSheetModule, MatIconModule]
})
export class NotificationtToastMessageComponent {
	@Input()
	public data: NotificationtToastMessageComponentData | null = null;

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) _data: NotificationtToastMessageComponentData,
		private _bottomSheetRef: MatBottomSheetRef<NotificationtToastMessageComponent>
	) {
		this.data = _data;
	}

	/** Close the notification toast. */
	public close(): void {
		this._bottomSheetRef.dismiss();
	}
}
