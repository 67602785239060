import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from '../services';

@Directive({
	standalone: true,
	selector: '[hasClaim]'
})
export class HasClaimDirective {
	constructor(
		private readonly _templateRef: TemplateRef<unknown>,
		private readonly _viewContainer: ViewContainerRef,
		private readonly _securityService: SecurityService
	) {}

	@Input() set hasClaim(claimType: string | string[]) {
		if (this._securityService.hasClaim(claimType)) {
			// Add template to DOM.
			this._viewContainer.createEmbeddedView(this._templateRef);
		} else {
			// Remove template from DOM.
			this._viewContainer.clear();
		}
	}
}
