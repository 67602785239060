/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AddressUseApiEnum {
    Home = 0,
    Work = 1,
    Temp = 2,
    Old = 3,
    Billing = 4,
}

export const AddressUseApiEnumDisplayMap : { [key in AddressUseApiEnum] : string } = {
    [AddressUseApiEnum.Home]: "Home",
    [AddressUseApiEnum.Work]: "Work",
    [AddressUseApiEnum.Temp]: "Temp",
    [AddressUseApiEnum.Old]: "Old",
    [AddressUseApiEnum.Billing]: "Billing",
}
