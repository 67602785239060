import { ChangeDetectionStrategy, Component, forwardRef, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged, Observable, of, startWith, switchMap } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { pluralize } from '../../helpers';
import { LowercaseExceptFirstPipe } from '../../pipes/lowercase-except-first.pipe';

export interface ListItem {
	key?: string | null | undefined;
	label: string;
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'list-builder',
	templateUrl: './list-builder.component.html',
	styleUrl: './list-builder.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatListModule,
		MatButtonModule,
		MatIconModule,
		MatAutocompleteModule,
		LowercaseExceptFirstPipe
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ListBuilderComponent),
			multi: true
		}
	]
})
export class ListBuilderComponent implements ControlValueAccessor {
	private _itemName: string | null | undefined = null;
	private _itemNamePlural: string | null | undefined = null;

	public listItems: ListItem[] = [];

	protected onTouched!: () => void;
	protected onChange!: (values: ListItem[] | null | undefined) => void;

	public readonly = input<boolean>(false);

	public readonly formGroup: FormGroup = new FormGroup({
		newItem: new FormControl<string | null>(null, [Validators.required])
	});

	get newItemCtrl(): FormControl {
		return this.formGroup.get('newItem') as FormControl;
	}

	@Input()
	set itemName(value: string | null | undefined) {
		if (value) {
			this._itemName = value;
		}
	}

	get itemName(): string {
		return this._itemName ?? 'Item';
	}

	@Input()
	set itemNamePlural(value: string | null | undefined) {
		if (value) {
			this._itemNamePlural = value;
		}
	}

	get itemNamePlural(): string {
		return this._itemNamePlural ?? pluralize(this.itemName);
	}

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	public optionsFilterCallBack: ((value: string) => Observable<ListItem[]>) | null = null;

	public filteredOptions = this.newItemCtrl.valueChanges.pipe(
		startWith(''),
		debounceTime(300),
		distinctUntilChanged(),
		switchMap(val => {
			return this.filter(val || '');
		})
	);

	constructor() {}

	writeValue(obj: ListItem[] | null | undefined): void {
		this.listItems = obj ?? [];
	}

	registerOnChange(fn: (values: ListItem[] | null | undefined) => void): void {
		this.onChange = fn;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.formGroup.disable();
		} else {
			this.formGroup.enable();
		}
	}

	public remove(listItem: ListItem) {
		const indexToRemove = this.listItems.findIndex(f => f.key === listItem.key && f.label === listItem.label);

		if (indexToRemove > -1) {
			this.listItems.splice(indexToRemove, 1);

			this.onChange([...this.listItems]);
			this.onTouched();
		}
	}

	public add(): void {
		if (this.formGroup.invalid) {
			return;
		}

		const newItem: string = this.newItemCtrl.value;

		if (this.listItems.filter(f => f.key === null && f.label === newItem).length === 0) {
			this.listItems.push({ label: newItem } as ListItem);

			this.newItemCtrl.reset(null, { emitEvent: false });

			this.onChange([...this.listItems]);
			this.onTouched();
		} else {
			this.newItemCtrl.setValue(newItem, { emitEvent: false });
			this.newItemCtrl.setErrors({ duplicate: true });
		}
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		const selectedOption: ListItem = event.option.value;

		if (
			this.listItems.filter(f => f.key === selectedOption.key && f.label === selectedOption.label).length === 0
		) {
			this.listItems.push(selectedOption);

			this.newItemCtrl.reset(null, { emitEvent: false });

			this.onChange([...this.listItems]);
			this.onTouched();
		} else {
			this.newItemCtrl.setValue(selectedOption.label, { emitEvent: false });
			this.newItemCtrl.setErrors({ duplicate: true });
		}
	}

	private filter(value: string): Observable<ListItem[]> {
		if (this.optionsFilterCallBack) {
			return this.optionsFilterCallBack(value);
		} else {
			return of([]);
		}
	}
}
