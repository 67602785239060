import { Component } from '@angular/core';
import { ErrorPageComponent } from '../error-page';

@Component({
	standalone: true,
	selector: 'page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrl: './page-not-found.component.scss',
	imports: [ErrorPageComponent]
})
export class PageNotFoundComponent {}
