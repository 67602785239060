import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPasswordSettingsBaseDataApiModel } from '../../generated-models';
import { NotificationService } from '../../layout';
import { BaseService } from '../../shared';

@Injectable({
	providedIn: 'root'
})
export class UserPasswordSettingsService extends BaseService<UserPasswordSettingsBaseDataApiModel> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings/password', _httpClient, _notificationService);
	}
}
