@if (showFormField) {
	<mat-form-field [ngClass]="{ 'full-width': fullWidth }">
		@if (label) {
			<mat-label>{{ label }}</mat-label>
		}

		@if (focusInitial) {
			<input
				matInput
				type="text"
				[placeholder]="placeHolder ? placeHolder : ''"
				[formControl]="formCtrl"
				cdkFocusInitial />
		} @else {
			<input matInput type="text" [placeholder]="placeHolder ? placeHolder : ''" [formControl]="formCtrl" />
		}

		@if (formCtrl.enabled) {
			<button
				mat-icon-button
				aria-label="Generate a new GUID"
				(click)="setNewGuid($event)"
				[disabled]="formCtrl.disabled">
				<mat-icon>autorenew</mat-icon>
			</button>
		}

		@if (formCtrl && formCtrl.hasError('required')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
		}

		@if (formCtrl && formCtrl.hasError('invalidGuid')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is not a valid guid.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl && formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</mat-form-field>
}
