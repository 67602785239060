<mat-card>
	@if (title || editable) {
		<mat-card-header>
			<mat-card-title>
				<span class="title">{{ title }}</span>
				@if (editable) {
					<button mat-icon-button aria-label="Edit button">
						<mat-icon>edit</mat-icon>
					</button>
				}
			</mat-card-title>
		</mat-card-header>
	}
	<mat-card-content>
		<ng-content></ng-content>
	</mat-card-content>
</mat-card>
