import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ChildrenOutletContexts, RouterModule } from '@angular/router';
import { NavigationLink } from '../admin/admin.component';
import { NavigationPanelLayoutComponent } from '../layout';
import { fadeInOutRouteAnimation } from '../shared/animations/fade-in-out-route-animation';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'user-settings',
	templateUrl: './user-settings.component.html',
	styleUrl: './user-settings.component.scss',
	imports: [RouterModule, forwardRef(() => NavigationPanelLayoutComponent)],
	animations: [fadeInOutRouteAnimation]
})
export class UserSettingsComponent {
	public readonly navigationLinks: NavigationLink[] = [
		{
			name: 'Detail',
			routerLink: 'detail',
			icon: 'info'
		},
		{
			name: 'MFA',
			routerLink: 'mfa',
			icon: 'phonelink_lock'
		},
		{
			name: 'Password',
			routerLink: 'password',
			icon: 'password'
		}
	];

	constructor(private readonly _contexts: ChildrenOutletContexts) {}

	getRouteAnimationData() {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}
}
