<form [formGroup]="formGroup">
	<auth-card [headerText]="'SophosMed EMR'" headerSubText="Confirm Email">
		<ng-content card-content>
			@if (isProcessing$ | async) {
				<span>Your email is being confirmed please wait.</span>
			} @else {
				@if (isEmailConfirmed) {
					<span>Your email has been confirmed you can now login.</span>
				} @else {
					<span
						>Your email was not able to be confirmed. The link may be expired, malformed, or you have already
						confirmed this email.</span
					>
				}
			}
		</ng-content>

		<ng-content card-actions>
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Go to login</a
			>
		</ng-content>
	</auth-card>
</form>
