<mat-toolbar>
	<button
		mat-icon-button
		aria-label="Toggle navigation menu"
		class="toggle-navigation-menu-button"
		(click)="opened = !opened">
		@if (opened) {
			<mat-icon aria-hidden="false">menu_open</mat-icon>
		} @else {
			<mat-icon aria-hidden="false">menu</mat-icon>
		}
	</button>

	<ng-content select="[toolbar-content]"></ng-content>
</mat-toolbar>
<mat-drawer-container>
	<mat-drawer mode="side" [(opened)]="opened">
		<mat-nav-list>
			@for (navigationLink of navigationLinks; track navigationLink) {
				<mat-list-item
					[routerLink]="navigationLink.routerLink"
					[ngClass]="{ 'line-break-list-item': navigationLink.name.length > 18 }">
					<mat-icon aria-hidden="false" matListItemIcon>{{ navigationLink.icon }}</mat-icon>
					<span matListItemTitle>{{ navigationLink.name }}</span>
				</mat-list-item>
			}
		</mat-nav-list>
	</mat-drawer>
	<mat-drawer-content>
		<ng-content select="[drawer-content]"></ng-content>
	</mat-drawer-content>
</mat-drawer-container>
