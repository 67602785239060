/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContactPointUseApiEnum {
    Home = 0,
    Work = 1,
    Temp = 2,
    Old = 3,
    Mobile = 4,
}

export const ContactPointUseApiEnumDisplayMap : { [key in ContactPointUseApiEnum] : string } = {
    [ContactPointUseApiEnum.Home]: "Home",
    [ContactPointUseApiEnum.Work]: "Work",
    [ContactPointUseApiEnum.Temp]: "Temp",
    [ContactPointUseApiEnum.Old]: "Old",
    [ContactPointUseApiEnum.Mobile]: "Mobile",
}
