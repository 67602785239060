import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map, Observable, of, take } from 'rxjs';
import { AuthService } from '../../auth';

export const TenantGuard: CanActivateFn = (next: ActivatedRouteSnapshot): Observable<boolean> => {
	const authService = inject(AuthService);
	const router = inject(Router);

	const tenantCode = next.params['tenantCode'];

	// Only validate if it changes.
	if (authService.tenantCode != tenantCode) {
		return authService.validateAndSetTenantCode(tenantCode).pipe(
			take(1),
			map(response => {
				if (response.ok) {
					return true;
				} else {
					router.navigate([`tenant-access-denied`]);
					return false;
				}
			})
		);
	}

	return of(true);
};
