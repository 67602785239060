@if (showFormField) {
	<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
		@if (label) {
			<mat-label>{{ label }}</mat-label>
		}
		<div class="flex-container">
			@if (focusInitial) {
				<input matInput [formControl]="formCtrl" [mtxColorpicker]="picker" [format]="'hex'" cdkFocusInitial />
				<mtx-colorpicker-toggle matSuffix [for]="picker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #picker></mtx-colorpicker>
			} @else {
				<input matInput [formControl]="formCtrl" [mtxColorpicker]="picker" [format]="'hex'" />
				<mtx-colorpicker-toggle matSuffix [for]="picker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #picker></mtx-colorpicker>
			}
		</div>

		@if (formCtrl && formCtrl.hasError('required')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
		}

		@if (formCtrl && formCtrl.hasError('invalidColorHex')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is invalid.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl && formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</mat-form-field>
}
