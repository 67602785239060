import { CommonModule } from '@angular/common';
import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { take, tap } from 'rxjs';
import { AuthService } from '../../auth';
import { ChangeUserFacilityRequestApiModel, IdLabelApiModel } from '../../generated-models';
import { BaseIsProcessingComponent, DefaultPipe, SelectInputFormFieldComponent } from '../../shared';
import { AppSidenavService, DATA_INJECTION_TOKEN } from '../app-sidenav';

export type ChangeFacilityComponentData = object;

@Component({
	standalone: true,
	selector: 'change-facility-component',
	templateUrl: './change-facility.component.html',
	styleUrl: './change-facility.component.scss',
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		DefaultPipe,
		MatSidenavModule,
		ReactiveFormsModule,
		forwardRef(() => SelectInputFormFieldComponent)
	]
})
export class ChangeFacilityComponent extends BaseIsProcessingComponent implements OnInit, OnDestroy {
	@Input()
	public data: ChangeFacilityComponentData | null = null;

	public facilities: IdLabelApiModel[] = [];

	public formGroup: FormGroup = new FormGroup({
		facilityId: new FormControl<number | null>(null, Validators.required)
	});

	get facilityIdCtrl() {
		return this.formGroup.get('facilityId') as FormControl;
	}

	constructor(
		@Inject(DATA_INJECTION_TOKEN) _data: ChangeFacilityComponentData,
		public appSidenavService: AppSidenavService,
		private _authService: AuthService
	) {
		super([]);

		if (_data) {
			this.data = _data;
		}
	}

	public ngOnInit(): void {
		this._authService
			.getUserFacilities()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.facilities = response.body ?? [];
					}
				})
			)
			.subscribe();

		this.populateForm();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public save(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const request: ChangeUserFacilityRequestApiModel = {
				facilityId: this.facilityIdCtrl.value
			};

			this._authService
				.changeUserFacility(request)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this.appSidenavService.closeSidenav();
						}
					})
				)
				.subscribe();
		}
	}

	private populateForm(): void {
		this.formGroup.patchValue({
			facilityId: this._authService.userInfo()?.facility?.id
		});
	}
}
