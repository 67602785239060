import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
	standalone: true,
	selector: 'guid-input-form-field',
	templateUrl: './guid-input-form-field.component.html',
	styleUrl: './guid-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule
	]
})
export class GuidInputFormFieldComponent
	extends BaseInputFormFieldComponent
	implements OnInit
{
	private _defaultWithNewGuid = false;

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	set defaultWithNewGuid(value: BooleanInput) {
		this._defaultWithNewGuid = coerceBooleanProperty(value);
	}

	constructor() {
		super('The guid');
	}

	public ngOnInit(): void {
		if (this._defaultWithNewGuid) {
			this.formCtrl.setValue(this.generateNewGuid(), { emitEvent: false });
		}
	}

	public setNewGuid(event: Event): void {
		event.stopPropagation();
		this.formCtrl.setValue(this.generateNewGuid(), { emitEvent: false });
	}

	private generateNewGuid(): string {
		return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
			(
				+c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
			).toString(16)
		);
	}
}
