/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EntityTypeApiEnum {
    System = 1,
    ExternalSystem = 2,
    Tenant = 3,
    Facility = 4,
    FacilityRoom = 5,
    User = 6,
    ChiefComplaint = 7,
}

export const EntityTypeApiEnumDisplayMap : { [key in EntityTypeApiEnum] : string } = {
    [EntityTypeApiEnum.System]: "System",
    [EntityTypeApiEnum.ExternalSystem]: "ExternalSystem",
    [EntityTypeApiEnum.Tenant]: "Tenant",
    [EntityTypeApiEnum.Facility]: "Facility",
    [EntityTypeApiEnum.FacilityRoom]: "FacilityRoom",
    [EntityTypeApiEnum.User]: "User",
    [EntityTypeApiEnum.ChiefComplaint]: "ChiefComplaint",
}
