/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RequestStatus {
    Draft = 0,
    Active = 1,
    OnHold = 2,
    Revoked = 3,
    Completed = 4,
    EnteredInError = 5,
    Unknown = 6,
}

export const RequestStatusDisplayMap : { [key in RequestStatus] : string } = {
    [RequestStatus.Draft]: "Draft",
    [RequestStatus.Active]: "Active",
    [RequestStatus.OnHold]: "OnHold",
    [RequestStatus.Revoked]: "Revoked",
    [RequestStatus.Completed]: "Completed",
    [RequestStatus.EnteredInError]: "EnteredInError",
    [RequestStatus.Unknown]: "Unknown",
}
