<div class="toolbar-title-container">
	<div class="toolbar-title">
		<span>{{ title }}</span>

		@if (count !== null) {
			<div class="count-circle">{{ count }}</div>
		}
	</div>

	<div class="action-button-content-container">
		<ng-content select="[action-button-content]"></ng-content>
	</div>

	@if (useFilters) {
		<div class="toolbar-filter">
			<button mat-icon-button aria-label="" (click)="toggleFilters()">
				<mat-icon>{{ filtersExpanded ? 'filter_alt_off' : 'filter_alt' }}</mat-icon>
			</button>
		</div>
	}
</div>

@if (useFilters) {
	<div [@expand]="filtersExpanded">
		<div class="filters-container">
			<div class="filters-content-container">
				<ng-content select="[filters-content]"></ng-content>
			</div>

			@if (showClearFilterButton) {
				<div class="filters-clear-container">
					<button
						[disabled]="isProcessing || disableClearFilterButton"
						mat-icon-button
						aria-label="clear all filters"
						(click)="clearAllFilters.emit()"
						matTooltip="Clear All Filters">
						<mat-icon>clear_all</mat-icon>
					</button>
				</div>
			}
		</div>
	</div>
}
