import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationMessageComponentData } from './confirmation-message/confirmation-message.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationService {
	private _confirmationQueue: ConfirmationMessageComponentData[] = [];

	private readonly _confirmationQueueCount: Subject<number> = new Subject();

	public readonly confirmationQueueCount$: Observable<number> = this._confirmationQueueCount.asObservable();

	constructor() {}

	public getNextConfirmationFromQueue(): ConfirmationMessageComponentData | null {
		if (this._confirmationQueue?.length > 0) {
			// Get the last (oldest) value and remove it from the array, LIFO.
			const nextNotification: ConfirmationMessageComponentData = this._confirmationQueue.splice(
				this._confirmationQueue.length - 1,
				1
			)[0];

			// Update the notification queue count.
			this._confirmationQueueCount.next(this._confirmationQueue.length);
			return nextNotification;
		} else {
			return null;
		}
	}

	public showConfirmationWithCallBack(title: string, confirmationCallback: () => void): void {
		return this.showConfirmation(title, null, null, null, confirmationCallback, null);
	}

	public showConfirmation(
		title: string | null = null,
		subtitle: string | null = null,
		confirmationText: string | null = null,
		cancelText: string | null = null,
		confirmationCallback: (() => void) | null = null,
		cancelCallback: (() => void) | null = null
	): void {
		const data = {
			title: title,
			subtitle: subtitle,
			confirmationText: confirmationText,
			cancelText: cancelText,
			confirmationCallback,
			cancelCallback
		} as ConfirmationMessageComponentData;

		this._confirmationQueue.push(data);
		this._confirmationQueueCount.next(this._confirmationQueue.length);
	}
}
