<form [formGroup]="formGroup">
	<select-input-form-field
		label="Facility"
		[formCtrl]="facilityIdCtrl"
		fullWidth
		focusInitial
		[options]="facilities"></select-input-form-field>

	<div class="fill-space"></div>

	<div class="action-buttons">
		<button type="submit" mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
			<mat-icon aria-hidden="false">domain</mat-icon>Change Facility
		</button>
	</div>
</form>
