<mat-action-list>
	@for (roomHelper of roomListHelper(); let isLast = $last; track roomHelper.id) {

	<button
		mat-list-item
		[title]="roomHelper.id"
		[disabled]="!roomHelper.enabled"
		(click)="changePatientRoom(roomHelper.room)">
		<mat-icon aria-hidden="false" matBadgeSize="small" class="table-row-icon">{{ roomHelper.icon }} </mat-icon>
		{{ roomHelper.label }}
	</button>
	}
</mat-action-list>
