@if (showFormField) {
	<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
		@if (label) {
			<mat-label>{{ label }}</mat-label>
		}

		@if (focusInitial) {
			<input
				matInput
				[formControl]="formCtrl"
				[matDatepicker]="picker"
				[readonly]="readonly()"
				cdkFocusInitial />
		} @else {
			<input matInput [formControl]="formCtrl" [matDatepicker]="picker" [readonly]="readonly()" />
		}

		@if (hint) {
			<mat-hint>{{ hint }}</mat-hint>
		}

		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker [touchUi]="touchUi" #picker></mat-datepicker>

		@if (formCtrl.hasError('required')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</mat-form-field>
}
