import { HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserPasswordSettingsBaseDataApiModel } from '../../generated-models';
import { UserPasswordSettingsService } from './user-password-settings.service';

export const userPasswordSettingsResolver: ResolveFn<
	HttpResponse<UserPasswordSettingsBaseDataApiModel>
> = () => {
	return inject(UserPasswordSettingsService).getBaseData();
};
