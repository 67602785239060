/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CareTeamStatusApiEnum {
    Inactive = 0,
    EnteredInError = 1,
    Suspended = 2,
    Proposed = 3,
    Active = 4,
    Unknown = 5,
}

export const CareTeamStatusApiEnumDisplayMap : { [key in CareTeamStatusApiEnum] : string } = {
    [CareTeamStatusApiEnum.Inactive]: "Inactive",
    [CareTeamStatusApiEnum.EnteredInError]: "EnteredInError",
    [CareTeamStatusApiEnum.Suspended]: "Suspended",
    [CareTeamStatusApiEnum.Proposed]: "Proposed",
    [CareTeamStatusApiEnum.Active]: "Active",
    [CareTeamStatusApiEnum.Unknown]: "Unknown",
}
