import { Pipe } from '@angular/core';
import { phoneFormatter } from '../formatters';

@Pipe({
	name: 'phone',
	standalone: true
})
export class PhonePipe {
	transform(phoneNumber: string | null | undefined, countryCode: number | null = null) {
		return phoneFormatter(phoneNumber, countryCode);
	}
}
