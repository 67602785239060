import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, filter, take, takeUntil, tap } from 'rxjs';
import {
	IdLabelApiModel,
	LoginRequestApiModel,
	MFAOptionApiModel,
	MFARequestApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import {
	BaseIsProcessingComponent,
	MFACodeInputFormFieldComponent,
	mfaCodeValidator,
	SelectInputFormFieldComponent,
	TextInputFormFieldComponent
} from '../../shared';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	selector: 'mfa',
	templateUrl: './mfa.component.html',
	styleUrl: './mfa.component.scss',
	imports: [
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		forwardRef(() => SelectInputFormFieldComponent),
		forwardRef(() => TextInputFormFieldComponent),
		MFACodeInputFormFieldComponent
	]
})
export class MFAComponent extends BaseIsProcessingComponent implements OnInit, OnDestroy {
	public mfaOption: typeof MFAOptionApiModel = MFAOptionApiModel;

	public formGroup: FormGroup = new FormGroup({
		mfaOptionId: new FormControl<MFAOptionApiModel>(MFAOptionApiModel.None, [Validators.required]),

		mfaCode: new FormControl<string | null>({ value: null, disabled: true }, [mfaCodeValidator()]),

		username: new FormControl<string | null>(null, [Validators.required, Validators.email]),

		password: new FormControl<string | null>(null, [Validators.required])
	});

	private _selectedMFAOption = new BehaviorSubject<MFAOptionApiModel>(MFAOptionApiModel.None);
	public $selectedMFAOption = this._selectedMFAOption.asObservable();

	private _mfaRequestSent = new BehaviorSubject<boolean>(false);
	public $mfaRequestSent = this._mfaRequestSent.asObservable();

	get mfaOptionIdCtrl() {
		return this.formGroup.get('mfaOptionId') as FormControl;
	}

	get mfaCodeCtrl() {
		return this.formGroup.get('mfaCode') as FormControl;
	}

	get usernameCtrl() {
		return this.formGroup.get('username') as FormControl;
	}

	get passwordCtrl() {
		return this.formGroup.get('password') as FormControl;
	}

	public mfaOptions: IdLabelApiModel[] = [];

	constructor(
		public readonly authService: AuthService,
		readonly _appLoaderService: AppLoaderService,
		private readonly _notificationService: NotificationService,
		private readonly _router: Router
	) {
		super([authService.isProcessing$]);

		const processId = 'mfa-component-loader-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();
	}

	public ngOnInit(): void {
		const username: string | null = this._router.lastSuccessfulNavigation?.extras?.state?.['username'];
		const password: string | null = this._router.lastSuccessfulNavigation?.extras?.state?.['password'];
		const mfaOptions: IdLabelApiModel[] | null =
			this._router.lastSuccessfulNavigation?.extras?.state?.['mfaOptions'];

		if (!username || !password || !mfaOptions || mfaOptions?.length == 0) {
			// Redirect to the login page as a login attempt needs to happen first.
			this._router.navigate([`/${this.authService.tenantCode}/auth/login`]);
		} else {
			this.mfaOptions = mfaOptions;
		}

		this.mfaOptionIdCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				filter(mfaOptionId => mfaOptionId),
				tap((mfaOptionId: MFAOptionApiModel) => {
					this._mfaRequestSent.next(false);
					this.mfaCodeCtrl.reset(null, { emitEvent: false });
					this.mfaCodeCtrl.disable({ emitEvent: false });
					this._selectedMFAOption.next(mfaOptionId);

					if (mfaOptionId === MFAOptionApiModel.TOTP) {
						this.mfaCodeCtrl.enable({ emitEvent: false });
					} else {
						this.mfaCodeCtrl.disable({ emitEvent: false });
					}
				})
			)
			.subscribe();

		this._mfaRequestSent
			.pipe(
				takeUntil(this._destroy),
				tap(mfaRequestSent => {
					if (
						mfaRequestSent &&
						(this.mfaOptionIdCtrl.value === MFAOptionApiModel.PhoneCall ||
							this.mfaOptionIdCtrl.value === MFAOptionApiModel.SMS)
					) {
						this.mfaCodeCtrl.enable({ emitEvent: false });
					} else {
						this.mfaCodeCtrl.disable({ emitEvent: false });
					}
				})
			)
			.subscribe();

		this.formGroup.patchValue(
			{
				username: username,
				password: password
			},
			{ emitEvent: false }
		);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this._selectedMFAOption.complete();
		this._mfaRequestSent.complete();
	}

	public requestMFACode(): void {
		switch (this.mfaOptionIdCtrl.value) {
			case MFAOptionApiModel.SMS:
				this.requestSMSMFACode();
				break;
			case MFAOptionApiModel.PhoneCall:
				this.requestCallMFACode();
				break;
			case MFAOptionApiModel.TOTP:
				break;
		}
	}

	private requestSMSMFACode(): void {
		const mfaRequest = {
			tenantCode: this.authService.tenantCode,
			email: this.usernameCtrl.value,
			password: this.passwordCtrl.value
		} as MFARequestApiModel;

		this.authService
			.requestSMSMFACode(mfaRequest)
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this._mfaRequestSent.next(true);
					}
				})
			)
			.subscribe();
	}

	private requestCallMFACode(): void {
		const mfaRequest = {
			tenantCode: this.authService.tenantCode!,
			email: this.usernameCtrl.value!,
			password: this.passwordCtrl.value!
		} as MFARequestApiModel;

		this.authService
			.requestCallMFACode(mfaRequest)
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this._mfaRequestSent.next(true);
					}
				})
			)
			.subscribe();
	}

	public loginMFA(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid && this.authService.tenantCode) {
			const loginRequest: LoginRequestApiModel = {
				email: this.usernameCtrl.value,
				password: this.passwordCtrl.value,
				tenantCode: this.authService.tenantCode,
				mfaOption: this.mfaOptionIdCtrl.value,
				twoFactorCode: this.mfaCodeCtrl.value
			};

			this.authService
				.login(loginRequest, () => {
					this._router.navigate([`${this.authService.tenantCode}`]);
				})
				.subscribe();
		}
	}
}
