@if (showFormField) {
	<div class="boolean-radio-input-form-field-container" [ngClass]="{ 'full-width': fullWidth, short: short }">
		<fieldset>
			@if (label) {
				<legend>{{ label }}</legend>
			}

			@if (focusInitial) {
				<mat-radio-group [formControl]="formCtrl" aria-label="Select an option" cdkFocusInitial>
					<mat-radio-button class="true-radio-button" [value]="true">{{ trueLabel }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ falseLabel }}</mat-radio-button>
				</mat-radio-group>
			} @else {
				<mat-radio-group [formControl]="formCtrl" aria-label="Select an option">
					<mat-radio-button class="true-radio-button" [value]="true">{{ trueLabel }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ falseLabel }}</mat-radio-button>
				</mat-radio-group>
			}

			@if (formCtrl && formCtrl.hasError('required')) {
				<mat-error>A {{ errorLabelToLower }} selection is required.</mat-error>
			}

			<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
				@if (formCtrl && formCtrl.hasError(customError.type)) {
					<mat-error>{{ customError.message }}</mat-error>
				}
			</ng-template>
		</fieldset>
	</div>
}
