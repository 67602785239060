import { Pipe, PipeTransform } from '@angular/core';
import { lowercaseExceptFirst } from '../formatters';
@Pipe({
	name: 'lowercaseExceptFirst',
	standalone: true
})
export class LowercaseExceptFirstPipe implements PipeTransform {
	transform(value: string | null | undefined): string | null | undefined {
		return lowercaseExceptFirst(value);
	}
}
