import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { DefaultPipe } from '../../shared';
import { NavigationLink } from '../../admin/admin.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'navigation-panel-layout',
	templateUrl: './navigation-panel-layout.component.html',
	styleUrl: './navigation-panel-layout.component.scss',
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		DefaultPipe,
		MatButtonModule
	]
})
export class NavigationPanelLayoutComponent {
	@Input({ required: true })
	public navigationLinks: NavigationLink[] = [];

	public opened: boolean = true;
}
