import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'timeTrailingZero',
	standalone: true
})
export class TimeTrailingZeroPipe implements PipeTransform {
	transform(time: number): string {
		if (isNaN(time)) {
			return '00';
		}

		if (time < 10) {
			return `0${time}`;
		}

		return time.toString();
	}
}
