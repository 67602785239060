import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncounterApiModel, PatientListBaseDataApiModel } from '../generated-models';
import { NotificationService } from '../layout';
import { BaseService } from '../shared/services/base-service.service';

@Injectable({
	providedIn: 'root'
})
export class PatientListService extends BaseService<PatientListBaseDataApiModel> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('patient-list', _httpClient, _notificationService);
	}

	public getAllPatientsDetailed(): Observable<HttpResponse<EncounterApiModel[]>> {
		return this.get<EncounterApiModel[]>('patients', 'Failed to retrieve the dashboard data');
	}
}
