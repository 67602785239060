@if (showFormField) {
	<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
		@if (label) {
			<mat-label>{{ label }}</mat-label>
		}

		<mat-chip-grid #chipGrid aria-label="Selection" [formControl]="selectedOptionsCtrl">
			@for (selectedOption of getSelectedOptions; track selectedOption) {
				<mat-chip-row (removed)="remove(selectedOption)">
					{{ selectedOption.label }}
					<button matChipRemove [attr.aria-label]="'remove ' + selectedOption.label">
						<mat-icon>cancel</mat-icon>
					</button>
				</mat-chip-row>
			}
		</mat-chip-grid>

		<input
			#searchInput
			type="text"
			[attr.placeholder]="placeHolder"
			[formControl]="searchInputCtrl"
			[matChipInputFor]="chipGrid"
			[matAutocomplete]="auto"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)" />

		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
			@for (filteredOption of filteredOptions | async; track filteredOption.key) {
				<mat-option [value]="filteredOption">{{ filteredOption.label }}</mat-option>
			}
		</mat-autocomplete>

		@if (formCtrl && formCtrl.hasError('required')) {
			<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl && formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</mat-form-field>
}
