import {
	Component,
	HostBinding,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatPaginatorModule } from '@angular/material/paginator';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseIsProcessingComponent } from '../../shared';
import { AppLoaderDirective } from '../app-loader';

@Component({
	standalone: true,
	selector: 'table-container',
	templateUrl: './table-container.component.html',
	styleUrl: './table-container.component.scss',
	imports: [
		CommonModule,
		AppLoaderDirective,
		MatPaginatorModule,
		MatProgressSpinnerModule
	]
})
export class TableContainerComponent
	extends BaseIsProcessingComponent
	implements OnDestroy, OnInit
{
	private _heightOffset: number = 0;

	@HostBinding('style') height = 'height: 100vh;';

	@Input()
	set heightOffset(value: number) {
		this._heightOffset = value;
	}

	@Input()
	set isProcessing(value: boolean | null) {
		this._isProcessing.next(!!value);
	}

	constructor() {
		super([]);
	}

	public ngOnInit(): void {
		this.height = `height: calc(100vh - ${this._heightOffset}px);`;
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}
