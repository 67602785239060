import { Routes } from '@angular/router';
import { LoginComponent } from './login';
import { PageNotFoundComponent } from '../error-pages/page-not-found/page-not-found.component';
import { AuthLayoutComponent } from './auth-layout';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './confirm-email';
import { ResetPasswordComponent } from './reset-password';
import { authResolver } from './auth.resolver';
import { MFAComponent } from './mfa';
import { PasswordExpiredComponent } from './password-expired';
import { MFAPhoneNumberSetupComponent } from './mfa-phone-number-setup';
import { MFATOTPSetupComponent } from './mfa-totp-setup';

export const AUTHROUTES: Routes = [
	{
		path: '',
		component: AuthLayoutComponent,
		resolve: { baseData: authResolver },
		data: { animation: 'auth' },
		children: [
			{
				path: 'login',
				component: LoginComponent,
				data: { animation: 'login' }
			},
			{
				path: 'mfa',
				component: MFAComponent,
				data: { animation: 'mfa' }
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				data: { animation: 'forgot-password' }
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent,
				data: { animation: 'reset-password' }
			},
			{
				path: 'confirm-email',
				component: ConfirmEmailComponent,
				data: { animation: 'confirm-email' }
			},
			{
				path: 'password-expired',
				component: PasswordExpiredComponent,
				data: { animation: 'password-expired' }
			},
			{
				path: 'mfa-phone-number-setup',
				component: MFAPhoneNumberSetupComponent,
				data: { animation: 'mfa-phone-number-setup' }
			},
			{
				path: 'mfa-totp-setup',
				component: MFATOTPSetupComponent,
				data: { animation: 'mfa-totp-setup' }
			},
			{
				path: '**',
				component: PageNotFoundComponent,
				data: { animation: 'page-not-found' }
			}
		]
	}
];
