/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LocationStatusApiEnum {
    Suspended = 0,
    Active = 1,
    Inactive = 2,
}

export const LocationStatusApiEnumDisplayMap : { [key in LocationStatusApiEnum] : string } = {
    [LocationStatusApiEnum.Suspended]: "Suspended",
    [LocationStatusApiEnum.Active]: "Active",
    [LocationStatusApiEnum.Inactive]: "Inactive",
}
