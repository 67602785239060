import { SortDirection } from '@angular/material/sort';

export function sortDateHelper(a: Date, b: Date, direction: SortDirection): number {
	if (a < b) {
		return direction === 'asc' ? -1 : 1;
	}

	if (a > b) {
		return direction === 'asc' ? 1 : -1;
	}

	return 0;
}
