<form [formGroup]="formGroup">
	<auth-card
		[headerText]="(authService.$tenant | async)?.name ?? null"
		headerSubText="Setup Authenticator MFA"
		showInstructionsTextContent>
		<ng-container instructions-text-content>
			You need to validate using an authenticator app to finish setting up. Scan the code below using a tool such
			as
			<a
				href="https://support.microsoft.com/en-us/account-billing/download-microsoft-authenticator-351498fc-850a-45da-b7b6-27e523b8702a"
				>Microsoft Authenticator</a
			>™ or Google Authenticator™. Once scanned enter the code from the authenticator below.
		</ng-container>

		<ng-container card-content>
			@if (showGenerateSecretPanel) {
				<div class="qr-code-container">
					<qr-code [value]="secretUri ?? undefined" size="384" errorCorrectionLevel="M" />
					<span
						>Your secret key is <span class="authentication-secret-key">{{ secret }}</span> you can use this if you
						can't scan the QR code.</span
					>
				</div>

				<mfa-code-input-form-field
					label="Confirmation Code"
					[formCtrl]="totpConfirmationCodeCtrl"
					fullWidth></mfa-code-input-form-field>
			}
		</ng-container>

		<ng-container card-actions>
			@if (showGenerateSecretPanel) {
				<button
					type="submit"
					[disabled]="isProcessing$ | async"
					mat-flat-button
					cdkFocusInitial
					(click)="validateSetupTOTPMFA()">
					Verify
				</button>
			}
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Back to Login</a
			>
		</ng-container>
	</auth-card>
</form>
