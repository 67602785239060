<form [formGroup]="formGroup">
	<loading-container [isProcessing]="isProcessing$ | async">
		<password-input-form-field
			label="Current Password"
			[formCtrl]="oldPasswordCtrl"
			autoCompleteOff
			showVisibilityButton
			disableCopyPaste
			focusInitial
			fullWidth>
		</password-input-form-field>

		<password-input-form-field
			label="New Password"
			[formCtrl]="newPasswordCtrl"
			autoCompleteOff
			showVisibilityButton
			disableCopyPaste
			fullWidth>
		</password-input-form-field>

		<password-input-form-field
			label="Verify New Password"
			[formCtrl]="verifyNewPasswordCtrl"
			autoCompleteOff
			showVisibilityButton
			disableCopyPaste
			fullWidth
			[customErrors]="[
				{ type: 'passwordsDoNotMatch', message: 'The passwords do not match.' }
			]"></password-input-form-field>

		<ng-container container-action-content>
			<button type="submit" mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
				<mat-icon aria-hidden="false">save</mat-icon>Save
			</button>
		</ng-container>
	</loading-container>
</form>
