<form [formGroup]="formGroup">
	<auth-card
		[headerText]="(authService.$tenant | async)?.name ?? null"
		headerSubText="Forgot Password"
		showInstructionsTextContent>
		<ng-container instructions-text-content> Enter your email to request a password reset link. </ng-container>
		<ng-container card-content>
			<text-input-form-field label="Email" [formCtrl]="emailCtrl"></text-input-form-field>
		</ng-container>

		<ng-container card-actions>
			<button
				type="submit"
				[disabled]="isProcessing$ | async"
				mat-flat-button
				cdkFocusInitial
				(click)="forgotPassword()">
				Request {{ requestCount > 0 ? 'Another' : '' }} Reset Link
			</button>
			<a
				[disabled]="isProcessing$ | async"
				mat-button
				[routerLink]="'/' + authService.tenantCode + '/auth/login'"
				>Back to Login</a
			>
		</ng-container>
	</auth-card>
</form>
