import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'timeLeft',
	standalone: true
})
export class TimeLeftPipe implements PipeTransform {
	transform(seconds: number): string {
		if (isNaN(seconds) || seconds < 0) {
			return '0:00';
		}

		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;

		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	}
}
