import { CommonModule } from '@angular/common';
import { Component, forwardRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, filter, take, takeUntil, tap } from 'rxjs';
import {
	IdLabelApiModel,
	MFAOptionApiModel,
	ValidateSetupPhoneNumberFMARequestApiModel
} from '../../generated-models';
import { AppLoaderService } from '../../layout';
import {
	BaseIsProcessingComponent,
	MFACodeInputFormFieldComponent,
	mfaCodeValidator,
	SelectInputFormFieldComponent
} from '../../shared';
import { TextInputFormFieldComponent } from '../../shared/components/input-form-fields/text-input-form-field/text-input-form-field.component';
import { UserMFASettingsService } from '../../user-settings';
import { AuthCardComponent } from '../auth-card';
import { AuthService } from '../auth-service.service';

@Component({
	standalone: true,
	selector: 'mfa-phone-number-setup',
	templateUrl: './mfa-phone-number-setup.component.html',
	styleUrl: './mfa-phone-number-setup.component.scss',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent,
		SelectInputFormFieldComponent,
		forwardRef(() => TextInputFormFieldComponent),
		MFACodeInputFormFieldComponent
	]
})
export class MFAPhoneNumberSetupComponent extends BaseIsProcessingComponent implements OnDestroy {
	public formGroup: FormGroup = new FormGroup({
		mfaOptionId: new FormControl<MFAOptionApiModel>(MFAOptionApiModel.None, [Validators.required]),

		mfaCode: new FormControl<string | null>({ value: null, disabled: true }, [mfaCodeValidator()])
	});

	public mfaOption: typeof MFAOptionApiModel = MFAOptionApiModel;

	private _selectedMFAOption = new BehaviorSubject<MFAOptionApiModel>(MFAOptionApiModel.SMS);
	public $selectedMFAOption = this._selectedMFAOption.asObservable();

	private _mfaRequestSent = new BehaviorSubject<boolean>(false);
	public $mfaRequestSent = this._mfaRequestSent.asObservable();

	public mfaOptions: IdLabelApiModel[] = [
		{ id: MFAOptionApiModel.PhoneCall, label: 'Phone Call' } as IdLabelApiModel,
		{ id: MFAOptionApiModel.SMS, label: 'SMS' } as IdLabelApiModel
	];

	get mfaOptionIdCtrl() {
		return this.formGroup.get('mfaOptionId') as FormControl;
	}

	get mfaCodeCtrl() {
		return this.formGroup.get('mfaCode') as FormControl;
	}

	constructor(
		public readonly authService: AuthService,
		readonly _appLoaderService: AppLoaderService,
		private readonly _userMFASettingsService: UserMFASettingsService,
		private readonly _router: Router
	) {
		super([authService.isProcessing$, _userMFASettingsService.isProcessing$]);

		const processId = 'mfa-phone-number-setup-load-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();

		this.mfaOptionIdCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				filter(mfaOptionId => mfaOptionId),
				tap((mfaOptionId: MFAOptionApiModel) => {
					this._mfaRequestSent.next(false);
					this.mfaCodeCtrl.reset(null, { emitEvent: false });
					this.mfaCodeCtrl.disable({ emitEvent: false });
					this._selectedMFAOption.next(mfaOptionId);
				})
			)
			.subscribe();

		this._mfaRequestSent
			.pipe(
				takeUntil(this._destroy),
				tap(mfaRequestSent => {
					if (
						mfaRequestSent &&
						(this.mfaOptionIdCtrl.value === MFAOptionApiModel.PhoneCall ||
							this.mfaOptionIdCtrl.value === MFAOptionApiModel.SMS)
					) {
						this.mfaCodeCtrl.enable({ emitEvent: false });
					} else {
						this.mfaCodeCtrl.disable({ emitEvent: false });
					}
				})
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this._selectedMFAOption.complete();
		this._mfaRequestSent.complete();
	}

	public setupPhoneNumberMFA(): void {
		const isCall: boolean = this.mfaOptionIdCtrl.value === MFAOptionApiModel.PhoneCall;

		this._userMFASettingsService
			.setupPhoneNumberMFA(isCall)
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this._mfaRequestSent.next(true);
					}
				})
			)
			.subscribe();
	}

	public validateSetupPhoneNumberMFA(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const request: ValidateSetupPhoneNumberFMARequestApiModel = {
				code: this.mfaCodeCtrl.value
			};

			this._userMFASettingsService
				.validateSetupPhoneNumberMFA(request)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this._router.navigate([`/${this.authService.tenantCode}`]);
						}
					})
				)
				.subscribe();
		}
	}
}
