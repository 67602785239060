/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChiefComplaintTypeEnum {
    Misc = 0,
    Illness = 1,
    Injury = 2,
}

export const ChiefComplaintTypeEnumDisplayMap : { [key in ChiefComplaintTypeEnum] : string } = {
    [ChiefComplaintTypeEnum.Misc]: "Misc",
    [ChiefComplaintTypeEnum.Illness]: "Illness",
    [ChiefComplaintTypeEnum.Injury]: "Injury",
}
