/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AnswerOptionTypeEnum {
    None = 0,
    String = 1,
    Integer = 2,
    Coding = 3,
}

export const AnswerOptionTypeEnumDisplayMap : { [key in AnswerOptionTypeEnum] : string } = {
    [AnswerOptionTypeEnum.None]: "None",
    [AnswerOptionTypeEnum.String]: "String",
    [AnswerOptionTypeEnum.Integer]: "Integer",
    [AnswerOptionTypeEnum.Coding]: "Coding",
}
