import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettingsBaseDataApiModel } from '../generated-models';
import { NotificationService } from '../layout';
import { BaseService } from '../shared';

@Injectable({
	providedIn: 'root'
})
export class UserSettingsService extends BaseService<UserSettingsBaseDataApiModel> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings', _httpClient, _notificationService);
	}
}
